import React from 'react';
import ProductSubcategoryTable from '../../../components/ProductMasters/ProductSubcategory/ProductSubcategoryTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <ProductSubcategoryTable />
        </ContentView>
    );
};
export default index;
