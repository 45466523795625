import React, {useState, useEffect} from 'react';
import {
  Form,
  Upload,
  Button,
  Drawer,
  message,
  Typography,
  Descriptions,
  Col,
  TimePicker,
} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';
import {UploadOutlined} from '@ant-design/icons';
import {excelToJSObject} from '../../../helpers/import.js';

const RouteJourneyPlanImport = ({handleDrawerClose, isDrawerVisible, getRouteJourneyPlanList}) => {
  const [importedData, setImportedData] = useState([]);

  const submit = () => {
    message.loading({content: 'Processing...', key: 'form'});
    postApiWithAuthToken('/api/v1/admin/routeJourneyPlans/import', {data: importedData}).then(
      res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRouteJourneyPlanList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      }
    );
  };

  return (
    <Drawer
      title={'Import'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={() => submit()} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <div style={{marginBottom: '24px'}}>
        <Upload
          name="data"
          showUploadList={false}
          maxCount={1}
          beforeUpload={file => {
            excelToJSObject(file).then(data => {
              setImportedData(data);
            });
            return false;
          }}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
      {importedData.map(data => {
        return (
          <>
            <Typography.Title level={5} style={{marginBottom: '8px'}}>
              {data.route}
            </Typography.Title>
            <Descriptions bordered style={{backgroundColor: '#fff', marginBottom: '24px'}}>
              <Descriptions.Item span={4} label="Day 1">
                {data.day_1.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 2">
                {data.day_2.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 3">
                {data.day_3.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 4">
                {data.day_4.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 5">
                {data.day_5.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 6">
                {data.day_6.split(' ').join(', ')}
              </Descriptions.Item>
              <Descriptions.Item span={4} label="Day 7">
                {data.day_7.split(' ').join(', ')}
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      })}
    </Drawer>
  );
};
export default RouteJourneyPlanImport;
