import axios from 'axios';
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,
} from './types';
import setAuthToken from '../utils/setAuthToken';
// Load user
export const loadUser = () => async (dispatch) => {
    try {
        // dispatch(setLoading());
        if (localStorage.token) {
            setAuthToken(localStorage.token);
            const res = await axios.get(
                process.env.REACT_APP_BASE_URL + '/api/v1/admin/auth/user-details'
            );

            dispatch({ type: USER_LOADED, payload: res.data });
            return res.data;
        }
    } catch (error) {
        console.log(error.response);
        dispatch({
            type: AUTH_ERROR,
            payload: error.response,
        });
        return error.response;
    }
};

// Login user
export const login = (loginData) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-public-token': process.env.REACT_APP_PUBLIC_AUTH_TOKEN,
        },
    };
    try {
        //dispatch(setLoading());
        const res = await axios.post(
            process.env.REACT_APP_BASE_URL + '/api/v1/admin/auth/login',
            loginData,
            config
        );
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        return res.data;
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error.response });
        return error.response;
    }
};
// Logout
export const logout = () => {
    return { type: LOGOUT };
};
