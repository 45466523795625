import * as XLSX from 'xlsx';

export const excelToJSObject = async (importFile) => {
    return await convertToJson(await readFile(importFile));
};

const convertToJson = async (csv) => {
    var lines = csv.split('\n');
    var result = [];
    var headers = lines[0].split(',');
    for (var i = 1; i < lines.length; i++) {
        if (lines[i]) {
            var obj = {};
            var currentline = lines[i].split(',');
            for (var j = 0; j < headers.length; j++) {
                if (currentline[j]) {
                    obj[headers[j]] = currentline[j];
                }
            }
            if (Object.keys(obj).length) {
                result.push(obj);
            }
        }
    }

    return result; //JavaScript object
};

const readFile = async (importFile) => {
    return new Promise((resolve, reject) => {
        var f = importFile;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            resolve(data);
        };
        reader.readAsBinaryString(f);
    });
};