import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { message } from 'antd';
import { connect } from 'react-redux';

import { loadUser } from '../actions/authAction';

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    token,
    loadUser,
    condition,
    user,
    error,

    ...rest
}) => {
    useEffect(() => {
        (async () => {
            let result = await loadUser();
            if (result && result.data.errors) {
                if (result.data.errors.length) {
                    message.error(result.data.errors[0].msg);
                } else {
                    message.error(result.data.errors);
                }
            } else if (!result) {
                message.warning('Please log in!');
            }
        })();
    }, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated && !token ? (
                    <Redirect to='/login' />
                ) : user ? (
                    condition.includes(user.role) ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to='/404' />
                    )
                ) : null
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    user: state.auth.user,
    error: state.auth.error,
});

export default connect(mapStateToProps, { loadUser })(PrivateRoute);
