import React, { useState, lazy, Suspense, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Table,
    Row,
    Col,
    Space,
    message,
    Popconfirm,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { deleteApiWithAuthToken } from '../../../api';
const UserAdd = lazy(() => import('./UserAdd'));
const User = () => {
    const [form] = Form.useForm();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        getUserList();
    }, []);
    // To Get all Table Data
    const getUserList = () => {
        // getApiWithAuthToken('/api/v1/admin/auth/user-details').then((res) => {
        //     console.log(res.data);
        //     if (!res.error && res.data.length) {
        //         setUserList([]);
        //         res.data.map((each) =>
        //             setUserList((userList) => [
        //                 ...userList,
        //                 {
        //                     id: each.id,
        //                     name: each.name,
        //                     email: each.email,
        //                 },
        //             ])
        //         );
        //     } else {
        //         if (res.errors) {
        //             Object.values(res.errors).map((each) =>
        //                 message.error(each.msg)
        //             );
        //             setUserList([]);
        //         } else {
        //             console.log('this');
        //             message.error(res.errors);
        //             setUserList([]);
        //         }
        //     }
        // });
    };
    //Delete
    function handleDeleteClick(id) {
        deleteApiWithAuthToken(`/api/v1/admin/auth/user-details/${id}`).then(
            (res) => {
                console.log(res);
                message.loading({ content: 'Loading...', duration: 0.5 });
                if (res.error) {
                    setTimeout(() => {
                        message.success({
                            content: 'Not Deleted!',
                            duration: 2,
                        });
                    }, 1000);
                } else {
                    setTimeout(() => {
                        message.success({
                            content: 'Deleted!',
                            duration: 2,
                        });
                    }, 1000);
                    getUserList();
                }
            }
        );
    }
    /* Table Functions started */
    const [searchText, setSearchText] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        // ref={node => {
                        //   this.searchInput = node;
                        // }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() =>
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    // setTimeout(() => this.searchInput.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Delete',
            key: 'delete',
            render: (_, record) => (
                <Popconfirm
                    title='Are you sure to delete this country?'
                    onConfirm={handleDeleteClick(record.id)}
                >
                    <Button danger size='small'>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    /* Table Functions end */
    //Drawer Open function
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const showDrawerVisible = () => {
        showDrawer();
        form.resetFields();
    };
    //  Drawer Close function
    const handleCancel = () => {
        setIsDrawerVisible(false);
    };
    return (
        <div>
            <Suspense fallback={<h1>Still Loading…</h1>}>
                <UserAdd
                    getUserList={getUserList}
                    setIsDrawerVisible={setIsDrawerVisible}
                    isDrawerVisible={isDrawerVisible}
                    form={form}
                    handleCancel={handleCancel}
                />
            </Suspense>
            <Row style={{ margin: '2%' }}>
                <Col span={24}>
                    <Table
                        title={() => (
                            <Row justify='space-between'>
                                <h1 style={{ fontWeight: 'bold' }}>PROFILE</h1>
                                <Button
                                    type='primary'
                                    onClick={showDrawerVisible}
                                >
                                    ADD
                                </Button>
                            </Row>
                        )}
                        bordered
                        columns={columns}
                        dataSource={userList}
                        scroll={{ x: 1000 }}
                        size='small'
                    />
                </Col>
            </Row>
        </div>
    );
};
export default User;
