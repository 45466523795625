import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Space} from 'antd';
import {postApiWithAuthToken, getApiWithAuthToken, updateApiWithAuthToken} from '../../../api';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const ProductForm = ({handleDrawerClose, isDrawerVisible, getProductList, product}) => {
  const [form] = Form.useForm();

  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productSubcategoryList, setProductSubcategoryList] = useState([]);

  function getProductCategoryList() {
    getApiWithAuthToken('/api/v1/admin/productCategories').then(res => {
      if (res.data && res.data.length > 0) {
        setProductCategoryList(res.data);
      }
    });
  }
  function getProductSubcategoryList() {
    getApiWithAuthToken('/api/v1/admin/productSubcategories').then(res => {
      if (res.data && res.data.length > 0) {
        setProductSubcategoryList(res.data);
      }
    });
  }

  useEffect(() => {
    getProductCategoryList();
    getProductSubcategoryList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [product]);

  // Form Submit
  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    console.log(values);
    message.loading({content: 'Processing...', key: 'form'});
    if (product.id) {
      updateApiWithAuthToken(`/api/v1/admin/products/${product.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getProductList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/products', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getProductList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={product.id ? 'Edit product ' : 'Add product '}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={product.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ref_code" label="Ref Code" initialValue={product.ref_code}>
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          initialValue={
            productCategoryList.find(category => category.name === product.category)
              ? productCategoryList.find(category => category.name === product.category).id
              : null
          }
        >
          <Select showSearch placeholder="Select a category" optionFilterProp="children">
            {productCategoryList.length &&
              productCategoryList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="subcategory"
          label="Subcategory"
          initialValue={
            productSubcategoryList.find(subcategory => subcategory.name === product.subcategory)
              ? productSubcategoryList.find(subcategory => subcategory.name === product.subcategory)
                  .id
              : null
          }
        >
          <Select showSearch placeholder="Select a subcategory" optionFilterProp="children">
            {productSubcategoryList.length &&
              productSubcategoryList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.List name="barcodes" initialValue={product.barcodes}>
          {(fields, {add, remove}) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Space key={field.key} align={index ? 'baseline' : 'center'}>
                    <Form.Item name={[index, 'name']} label={index ? '' : 'Barcodes'}>
                      <Input placeholder="Barcode" />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{width: '60%'}}
                    icon={<PlusOutlined />}
                  >
                    Add barcode
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Drawer>
  );
};
export default ProductForm;
