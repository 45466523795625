import React from 'react';
import BranchTable from '../../../components/RouteMasters/Branch/BranchTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <BranchTable />
        </ContentView>
    );
};
export default index;
