import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Checkbox, InputNumber, Col} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const RouteForm = ({handleDrawerClose, isDrawerVisible, getRouteList, route}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [branchList, setBranchList] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);

  function getBranchList() {
    getApiWithPublicAuthToken('/api/v1/admin/branches').then(res => {
      if (res.data && res.data.length > 0) {
        setBranchList(res.data);
      }
    });
  }
  function getSalesmanList() {
    getApiWithPublicAuthToken('/api/v1/admin/salesmen').then(res => {
      if (res.data && res.data.length > 0) {
        setSalesmanList(res.data);
      }
    });
  }

  useEffect(() => {
    getBranchList();
    getSalesmanList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [route]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    values = {...values, visit_days: values.visit_days?.join(',')};
    message.loading({content: 'Processing...', key: 'form'});
    if (route.id) {
      updateApiWithAuthToken(`/api/v1/admin/routes/${route.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRouteList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/routes', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRouteList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={route.id ? 'Edit route' : 'Add route'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
        {/* <Form.Item
                    name="code"
                    label="Code"
                    initialValue={route.code}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item> */}
        <Form.Item
          name="name"
          label="Name"
          initialValue={route.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="visit_days"
          label="Regular visit days in a week"
          initialValue={route.visit_days?.split(',')}
        >
          <Select
            showSearch
            mode={'multiple'}
            placeholder="Select days"
            optionFilterProp="children"
          >
            <Select.Option key={1} value="sunday">
              Sunday
            </Select.Option>
            <Select.Option key={2} value="monday">
              Monday
            </Select.Option>
            <Select.Option key={3} value="tuesday">
              Tuesday
            </Select.Option>
            <Select.Option key={4} value="wednesday">
              Wednesday
            </Select.Option>
            <Select.Option key={5} value="thursday">
              Thursday
            </Select.Option>
            <Select.Option key={6} value="friday">
              Friday
            </Select.Option>
            <Select.Option key={7} value="saturday">
              Saturday
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="def_start_day"
          label="Weekday to start the weekend"
          initialValue={route.def_start_day}
        >
          <Select showSearch placeholder="Select a day" optionFilterProp="children">
            <Select.Option key={1} value="sunday">
              Sunday
            </Select.Option>
            <Select.Option key={2} value="monday">
              Monday
            </Select.Option>
            <Select.Option key={3} value="tuesday">
              Tuesday
            </Select.Option>
            <Select.Option key={4} value="wednesday">
              Wednesday
            </Select.Option>
            <Select.Option key={5} value="thursday">
              Thursday
            </Select.Option>
            <Select.Option key={6} value="friday">
              Friday
            </Select.Option>
            <Select.Option key={7} value="saturday">
              Saturday
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="multi_sod" valuePropName="checked" initialValue={route.multi_sod}>
          <Checkbox>Enable multiple SOD</Checkbox>
        </Form.Item>
        <Form.Item name="multi_day" valuePropName="checked" initialValue={route.multi_day}>
          <Checkbox>Enable multiple SOD in a day</Checkbox>
        </Form.Item>
        <Form.Item
          name="def_salesman"
          label="Salesman"
          initialValue={
            salesmanList.find(salesman => salesman.name === route.salesman)
              ? salesmanList.find(salesman => salesman.name === route.salesman).id
              : null
          }
        >
          <Select showSearch placeholder="Select a salesman" optionFilterProp="children">
            {salesmanList.length &&
              salesmanList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="branch"
          label="Branch"
          initialValue={
            branchList.find(branch => branch.name === route.branch)
              ? branchList.find(branch => branch.name === route.branch).id
              : null
          }
        >
          <Select showSearch placeholder="Select a branch" optionFilterProp="children">
            {branchList.length &&
              branchList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default RouteForm;
