import React from 'react';
import CustomerPriceTable from '../../../components/PriceMasters/CustomerPrice/CustomerPriceTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <CustomerPriceTable />
        </ContentView>
    );
};
export default index;
