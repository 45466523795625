import React, {useState, useEffect, useRef} from 'react';
import {
  Input,
  Button,
  Table,
  Row,
  Space,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, ReloadOutlined} from '@ant-design/icons';
import {getApiWithAuthToken} from '../../../api';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

const CollectionsSummary = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [collectionsSummaryList, setCollectionsSummaryList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getCollectionsSummaryList = ({
    limit = 10,
    page = 1,
    collection_code = null,
    customer_name = null,
    route_name = null,
    salesman_name = null,
    field = null,
    order = null,
  }) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/collectionsSummary?limit=${limit}&page=${page}` +
        (startDate == null ? '' : '&start_date=' + startDate) +
        (endDate == null ? '' : '&end_date=' + endDate) +
        (collection_code == null ? '' : '&collection_code=' + collection_code) +
        (customer_name == null ? '' : '&customer=' + customer_name) +
        (route_name == null ? '' : '&route=' + route_name) +
        (salesman_name == null ? '' : '&salesman=' + salesman_name) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data?.collections_summary?.length) {
        setTotalCount(res.data.total_count);
        // setStartDate(moment(res.data.start_date).format("YYYY-MM-DD"));
        // setEndDate(moment(res.data.end_date).format("YYYY-MM-DD"));
        setCollectionsSummaryList([]);
        res.data.collections_summary.map(each =>
          setCollectionsSummaryList(collectionsSummaryList => [
            ...collectionsSummaryList,
            {
              id: each.id,
              key: each.id,
              collection_code: each.collection_code,
              date: each.date,
              total_amount: each.total_amount,
              customer_name: each.customer_name,
              route_name: each.route_name,
              salesman_name: each.salesman_name,
              trans_type: [...new Set(each.trans_type.map(each => each.toLowerCase()))],
              sod_code: each.sod_code,
              currency: each.currency,
              status: each.status,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setCollectionsSummaryList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setCollectionsSummaryList([]);
          } else {
            message.error({content: 'Network Error!'});
            setCollectionsSummaryList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCollectionsSummaryList({limit: pageSize, page: currentPage});
  }, [startDate, endDate]);

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setCurrentPage(1);
    getCollectionsSummaryList({limit: pageSize, page: currentPage, [dataIndex]: selectedKeys[0]});
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    getCollectionsSummaryList({limit: pageSize, page: 1});
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getCollectionsSummaryList({
      limit: pagination.pageSize,
      page: pagination.current,
      collection_code: filters.collection_code,
      customer_name: filters.customer_name,
      route_name: filters.route_name,
      salesman_name: filters.salesman_name,
      field: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'collection_code',
      key: 'collection_code',
      ...getColumnSearchProps('collection_code'),
      sorter: (a, b) => (a.collection_code ?? '').localeCompare(b.collection_code ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      sorter: (a, b) => (a.total_amount ?? '').localeCompare(b.total_amount ?? ''),
    },
    {
      title: 'Trans. Type',
      key: 'trans_type',
      render: (_, record) => (
        <>
          {record.trans_type.map(e => {
            return <Tag>{e}</Tag>;
          })}
        </>
      ),
      filters: [
        {
          text: 'Credit',
          value: 'Credit',
        },
        {
          text: 'Cash',
          value: 'Cash',
        },
        {
          text: 'Temporary Credit',
          value: 'Temporary Credit',
        },
      ],
      onFilter: (value, record) =>
        record.trans_type.join(' ').toLowerCase().indexOf(value.toLowerCase()) > -1,
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      ...getColumnSearchProps('customer_name'),
      sorter: (a, b) => (a.customer_name ?? '').localeCompare(b.customer_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...getColumnSearchProps('route_name'),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...getColumnSearchProps('salesman_name'),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status == 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button
            size="small"
            onClick={() => {
              history.push(`/collections-details?collection_code=${record.collection_code}`);
            }}
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Collections Summary
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{marginLeft: '16px'}}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              setCurrentPage(1);
              // getCollectionsSummaryList(pageSize, 1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>

      <Table
        scroll={{x: 0}}
        dataSource={collectionsSummaryList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{backgroundColor: '#fff'}}>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status == 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="SOD Code">{record.sod_code}</Descriptions.Item>
              <Descriptions.Item label="Currency">{record.currency}</Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};
export default CollectionsSummary;
