import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Col, Row, InputNumber, Checkbox} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const ProductMultipackForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getProductMultipackList,
  productMultipack,
}) => {
  const [form] = Form.useForm();

  const [productList, setProductList] = useState([]);

  function getProductList() {
    getApiWithAuthToken('/api/v1/admin/products').then(res => {
      if (res.data && res.data.length > 0) {
        setProductList(res.data);
      }
    });
  }

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [productMultipack]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    if (productMultipack.id) {
      updateApiWithAuthToken(`/api/v1/admin/productMultipacks/${productMultipack.id}`, values).then(
        res => {
          if (!res.error) {
            setTimeout(() => {
              message.success({content: res.msg, key: 'form'});
            }, 500);
            handleDrawerClose();
            getProductMultipackList();
          } else {
            setTimeout(() => {
              if (res.errors.length) {
                res.errors.map(each => {
                  message.error({content: each.msg, key: 'form'});
                  return null;
                });
              } else {
                message.error({content: res.errors, key: 'form'});
              }
            }, 500);
          }
        }
      );
    } else {
      postApiWithAuthToken('/api/v1/admin/productMultipacks', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getProductMultipackList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={productMultipack.id ? 'Edit product multipack' : 'Add product multipack'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={productMultipack.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ref_code" label="Ref Code" initialValue={productMultipack.ref_code}>
          <Input />
        </Form.Item>
        <Form.Item
          name="product"
          label="Product"
          initialValue={
            productList.find(product => product.name === productMultipack.product)
              ? productList.find(product => product.name === productMultipack.product).id
              : null
          }
        >
          <Select
            showSearch
            placeholder="Select a product"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {productList.length &&
              productList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="base_unit"
          valuePropName="checked"
          initialValue={productMultipack.base_unit}
        >
          <Checkbox>Base unit</Checkbox>
        </Form.Item>
        <Form.Item
          name="base_multipack"
          label="Base multipack"
          initialValue={productMultipack.base_multipack}
        >
          <Input />
        </Form.Item>
        <Form.Item name="multiplier" label="Multiplier" initialValue={productMultipack.multiplier}>
          <InputNumber />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default ProductMultipackForm;
