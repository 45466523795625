import React, {useState, useEffect} from 'react';
import {Form, Input, Row, Col, Button, Drawer, message, Typography, Select} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const CustomDocumentCodeForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getCustomDocumentCodeList,
  customDocumentCode,
}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [routeList, setRouteList] = useState([]);
  const [generatedCode, setGeneratedCode] = useState('');
  const [positions, setPositions] = useState([
    {
      value: 0,
      usedBy: '',
    },
    {
      value: 1,
      usedBy: '',
    },
    {
      value: 2,
      usedBy: '',
    },
    {
      value: 3,
      usedBy: '',
    },
    {
      value: 4,
      usedBy: '',
    },
  ]);

  function getRouteList() {
    getApiWithPublicAuthToken('/api/v1/admin/routes').then(res => {
      if (res.data && res.data.length > 0) {
        setRouteList(res.data);
      }
    });
  }

  useEffect(() => {
    getRouteList();
  }, []);

  useEffect(() => {
    form.resetFields();

    const code = Array(10);

    code[customDocumentCode.slug_position] = customDocumentCode.slug;
    code[customDocumentCode.route_code] = '008';
    code[customDocumentCode.salesman_code] = '001';
    code[customDocumentCode.company_code] = '002';
    code[customDocumentCode.year] = '22';

    setGeneratedCode(code.filter(e => e).join(customDocumentCode.code_separator));
  }, [customDocumentCode]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (customDocumentCode.id) {
      updateApiWithAuthToken(
        `/api/v1/admin/customDocumentCodes/${customDocumentCode.id}`,
        values
      ).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCustomDocumentCodeList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/customDocumentCodes', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCustomDocumentCodeList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={customDocumentCode.id ? 'Edit custom document code' : 'Add custom document code'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        onValuesChange={() => {
          const values = form.getFieldsValue();

          const tempPositions = positions.map(p => ({...p, usedBy: ''}));

          if (tempPositions.find(p => p.value === values.slug_position))
            tempPositions.find(p => p.value === values.slug_position).usedBy = 'slug_position';
          if (tempPositions.find(p => p.value === values.route_code))
            tempPositions.find(p => p.value === values.route_code).usedBy = 'route_code';
          if (tempPositions.find(p => p.value === values.salesman_code))
            tempPositions.find(p => p.value === values.salesman_code).usedBy = 'salesman_code';
          if (tempPositions.find(p => p.value === values.company_code))
            tempPositions.find(p => p.value === values.company_code).usedBy = 'company_code';
          if (tempPositions.find(p => p.value === values.year))
            tempPositions.find(p => p.value === values.year).usedBy = 'year';

          setPositions(tempPositions);

          const code = Array(10);

          code[values.slug_position] = values.slug;
          code[values.route_code] = '008';
          code[values.salesman_code] = '001';
          code[values.company_code] = '002';
          code[values.year] = '22';

          setGeneratedCode(code.filter(e => e).join(values.code_separator));
        }}
      >
        <Row justify="space-between">
          <Col>
            <Typography.Title level={4}>{customDocumentCode.name}</Typography.Title>
          </Col>
          <Col>
            <Typography.Title level={5}>{generatedCode}</Typography.Title>
          </Col>
        </Row>
        <Form.Item name="slug" label="Slug" initialValue={customDocumentCode.slug}>
          <Input />
        </Form.Item>
        <Form.Item
          name="slug_position"
          label="Slug position"
          initialValue={customDocumentCode.slug_position}
        >
          <Select
            showSearch
            placeholder="Select a slug position"
            optionFilterProp="children"
            allowClear={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {positions
              .filter(p => p.usedBy === '' || p.usedBy === 'slug_position')
              .map(p => (
                <Select.Option value={p.value}>{p.value}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="route_code"
          label="Route code (eg: 008)"
          initialValue={customDocumentCode.route_code}
        >
          <Select
            showSearch
            placeholder="Select a route code position"
            allowClear={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {positions
              .filter(p => p.usedBy === '' || p.usedBy === 'route_code')
              .map(p => (
                <Select.Option value={p.value}>{p.value}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="salesman_code"
          label="Salesman code (eg: 001)"
          initialValue={customDocumentCode.salesman_code}
        >
          <Select
            showSearch
            placeholder="Select a salesman code position"
            optionFilterProp="children"
            allowClear={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {positions
              .filter(p => p.usedBy === '' || p.usedBy === 'salesman_code')
              .map(p => (
                <Select.Option value={p.value}>{p.value}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="company_code"
          label="Company code (eg: 002)"
          initialValue={customDocumentCode.company_code}
        >
          <Select
            showSearch
            placeholder="Select a company code position"
            optionFilterProp="children"
            allowClear={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {positions
              .filter(p => p.usedBy === '' || p.usedBy === 'company_code')
              .map(p => (
                <Select.Option value={p.value}>{p.value}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="year" label="Year (eg: 22)" initialValue={customDocumentCode.year}>
          <Select
            showSearch
            placeholder="Select a year position"
            optionFilterProp="children"
            allowClear={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {positions
              .filter(p => p.usedBy === '' || p.usedBy === 'year')
              .map(p => (
                <Select.Option value={p.value}>{p.value}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="min_length_1"
          label="Min length 1"
          initialValue={customDocumentCode.min_length_1}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="code_separator"
          label="Code separator"
          initialValue={customDocumentCode.code_separator}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default CustomDocumentCodeForm;
