import React from 'react';
import PriceKeyTable from '../../../components/PriceMasters/PriceKey/PriceKeyTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <PriceKeyTable />
        </ContentView>
    );
};
export default index;
