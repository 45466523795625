import React from 'react';
import RoutePriceTable from '../../../components/PriceMasters/RoutePrice/RoutePriceTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <RoutePriceTable />
        </ContentView>
    );
};
export default index;
