import React from 'react';
import CustomDocumentCodeTable from '../../../components/RouteMasters/CustomDocumentCode/CustomDocumentCodeTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
  return (
    <ContentView>
      <CustomDocumentCodeTable />
    </ContentView>
  );
};
export default index;
