import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Checkbox, InputNumber, Col} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';
import toTitleCase from 'helpers/toTitleCase';

const CustomerForm = ({handleDrawerClose, isDrawerVisible, getCustomerList, customer}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [transTypeList, setTransTypeList] = useState([]);
  const [customerTypeList, setCustomerTypeList] = useState([]);

  function getCountryList() {
    getApiWithPublicAuthToken('/api/v1/public/countryList').then(res => {
      if (res.data && res.data.length > 0) {
        setCountryList(res.data);
      }
    });
  }
  function getRegionList() {
    getApiWithAuthToken('/api/v1/admin/regions').then(res => {
      if (res.data && res.data.length > 0) {
        setRegionList(res.data);
      }
    });
  }
  function getAreaList() {
    getApiWithAuthToken('/api/v1/admin/areas').then(res => {
      if (res.data && res.data.length > 0) {
        setAreaList(res.data);
      }
    });
  }
  function getCurrencyList() {
    getApiWithPublicAuthToken('/api/v1/public/currencyList').then(res => {
      if (res.data && res.data.length > 0) {
        res.data.map(each => (each.name = toTitleCase(each.name)));
        setCurrencyList(res.data);
      }
    });
  }
  function getTransTypeList() {
    getApiWithAuthToken('/api/v1/admin/transactionTypes').then(res => {
      if (res.data && res.data.length > 0) {
        res.data.map(each => (each.name = toTitleCase(each.name)));
        setTransTypeList(res.data);
      }
    });
  }
  function getCustomerTypeList() {
    getApiWithAuthToken('/api/v1/admin/customerTypes').then(res => {
      if (res.data && res.data.length > 0) {
        setCustomerTypeList(res.data);
      }
    });
  }

  useEffect(() => {
    getCountryList();
    getRegionList();
    getAreaList();
    getCurrencyList();
    getTransTypeList();
    getCustomerTypeList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [customer]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    values.vat = values.vat / 100;
    message.loading({content: 'Processing...', key: 'form'});
    if (customer.id) {
      updateApiWithAuthToken(`/api/v1/admin/customers/${customer.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCustomerList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/customers', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCustomerList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({
                content: res.errors,
                key: 'form',
              });
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={customer.id ? 'Edit customer' : 'Add customer'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={customer.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          initialValue={
            customerTypeList.find(customerType => customerType.name === customer.customer_type)
              ? customerTypeList.find(customerType => customerType.name === customer.customer_type)
                  .id
              : null
          }
        >
          <Select showSearch placeholder="Select a customer type" optionFilterProp="children">
            {customerTypeList.length &&
              customerTypeList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description" initialValue={customer.description}>
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          initialValue={customer.address}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="po_box" label="PO box" initialValue={customer.po_box}>
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          initialValue={
            countryList.find(country => country.name === customer.country)
              ? countryList.find(country => country.name === customer.country).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a country" optionFilterProp="children">
            {countryList.length &&
              countryList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          initialValue={
            regionList.find(region => region.name === customer.region)
              ? regionList.find(region => region.name === customer.region).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a region" optionFilterProp="children">
            {regionList.length &&
              regionList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="area"
          label="Area"
          initialValue={
            areaList.find(area => area.name === customer.area)
              ? areaList.find(area => area.name === customer.area).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a area" optionFilterProp="children">
            {areaList.length &&
              areaList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="mobile" label="Mobile" initialValue={customer.mobile}>
          <Input />
        </Form.Item>
        <Form.Item name="contact_1" label="Contact 1" initialValue={customer.contact_1}>
          <Input />
        </Form.Item>
        <Form.Item name="contact_2" label="Contact 2" initialValue={customer.contact_2}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" initialValue={customer.email}>
          <Input />
        </Form.Item>
        <Form.Item name="website" label="Website" initialValue={customer.website}>
          <Input />
        </Form.Item>
        <Form.Item
          name="trans_type"
          label="Trans type"
          initialValue={customer ? customer.trans_type_id : null}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a transaction type" optionFilterProp="children">
            {transTypeList.length &&
              transTypeList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="multi_trans_type"
          valuePropName="checked"
          initialValue={customer.multi_trans_type}
        >
          <Checkbox>Multi trans type</Checkbox>
        </Form.Item>
        <Form.Item
          name="credit_limit_amt"
          label="Credit limit amt"
          initialValue={customer.credit_limit_amt}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="credit_limit_days"
          label="Credit limit days"
          initialValue={customer.credit_limit_days}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="latitude" label="Latitude" initialValue={customer.latitude}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="longitude" label="Longitude" initialValue={customer.longitude}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="bank_account_no"
          label="Bank Account Number"
          initialValue={customer.bank_account_no}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="bank_name" label="Bank name" initialValue={customer.bank_name}>
          <Input />
        </Form.Item>
        <Form.Item
          name="bank_payee_name"
          label="Bank payee name"
          initialValue={customer.bank_payee_name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="vat"
          label="VAT (in %)"
          initialValue={customer.vat ? customer.vat * 100 : null}
        >
          <InputNumber max={100} min={0} />
        </Form.Item>
        <Form.Item name="vat_reg_no" label="VAT reg no" initialValue={customer.vat_reg_no}>
          <Input />
        </Form.Item>
        <Form.Item
          name="def_currency"
          label="Def currency"
          initialValue={customer ? customer.def_currency_id : null}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select showSearch placeholder="Select a currency" optionFilterProp="children">
            {currencyList.length &&
              currencyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default CustomerForm;
