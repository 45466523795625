import React from 'react';
import CustomerTable from '../../../components/UserMasters/Customer/CustomerTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <CustomerTable />
        </ContentView>
    );
};
export default index;
