import React from 'react';
import {
    Layout,
    Avatar,
    Row,
    // Badge,
    Space,
    Menu,
    Dropdown,
} from 'antd';
import VF_logo from '../../assets/images/VF_logo.png';
import { logout } from '../../actions/authAction';
import {
    UserOutlined,
    SettingOutlined,
    // BellOutlined,
    LogoutOutlined,
    // AccountBookOutlined,
    // BorderOuterOutlined,
    // ShoppingOutlined,
    // DeliveredProcedureOutlined,
    // MessageOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const { Header } = Layout;

const HeaderView = ({ logout, user }) => {
    const history = useHistory();

    function handleClickLogOut() {
        logout();
    }

    const menuForAvatar = (
        <Menu>
            {/* <Menu.Item icon={<UserOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#703d98', }} />} >
                My Profile
            </Menu.Item>
            <Menu.Divider /> */}
            <Menu.Item
                onClick={() => history.push('/company-settings')}
                icon={
                    <SettingOutlined
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#703d98',
                        }}
                    />
                }
            >
                Company Settings
            </Menu.Item>
            {/* <Menu.Divider />
            <Menu.Item icon={<AccountBookOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#703d98', }} />}>
                Account Details
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<BorderOuterOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#703d98', }} />} >
                Your Orders
            </Menu.Item> */}
            <Menu.Divider />
            <Menu.Item
                icon={
                    <LogoutOutlined
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            color: '#703d98',
                        }}
                    />
                }
                onClick={handleClickLogOut}
            >
                Log Out
            </Menu.Item>
        </Menu>
    );

    // const notification = (
    //     <Menu>
    //         <Menu.Item icon={<MessageOutlined style={{ color: '#703d98' }} />}>
    //             You have a message from saran prasad
    //         </Menu.Item>
    //         <Menu.Divider />
    //         <Menu.Item icon={<ShoppingOutlined style={{ color: '#703d98', }} />}  >
    //             New product added
    //         </Menu.Item>
    //         <Menu.Divider />
    //         <Menu.Item icon={<DeliveredProcedureOutlined style={{ color: '#703d98', }} />} >
    //             Your package product shipped and will be delivered soon!
    //         </Menu.Item>
    //     </Menu>
    // );

    return (
        <Header
            className="site-layout-header"
            style={{ padding: '0 16px', background: 'white' }}
        >
            <Row justify="space-between">
                <div className="logo">
                    <img
                        style={{ height: '30px', cursor: 'pointer' }}
                        src={VF_logo}
                        alt="vanforces logo"
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                </div>

                <Space size={30} style={{ marginRight: '10px' }}>
                    {/* <Dropdown overlay={notification} placement='bottomRight' arrow trigger={['click']} >
                        <Badge count={5}>
                            <BellOutlined style={{ fontSize: '25px', color: '#666', cursor: 'pointer', }} />
                        </Badge>
                    </Dropdown> */}
                    <Dropdown
                        overlay={menuForAvatar}
                        overlayClassName="nav-bar-profile-menu"
                        placement="bottomCenter"
                        trigger={['click']}
                    >
                        {user && user.profile_image ? (
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '40px',
                                    backgroundImage: `url(${user.profile_image})`,
                                    backgroundSize: 'contain',
                                    cursor: 'pointer',
                                }}
                            ></div>
                        ) : (
                            <Avatar
                                size={32}
                                icon={<UserOutlined />}
                                style={{ cursor: 'pointer' }}
                            />
                        )}
                    </Dropdown>
                </Space>
            </Row>
        </Header>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});
export default connect(mapStateToProps, { logout })(HeaderView);
