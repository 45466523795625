import React from 'react';
import Content from '../../components/Layouts/ContentView';
import new_customer from '../../assets/images/new_customer.png';
import salesmen from '../../assets/images/salesmen.png';
import status from '../../assets/images/status.png';
import moment from 'moment';
import style from './Dashboard.module.css';
import {
  DatePicker,
  Button,
  Row,
  Col,
  Tabs,
  Card,
  Divider,
  Progress,
  Select,
  Typography,
} from 'antd';
import {PrinterOutlined} from '@ant-design/icons';
import Column from '../../components/Dashboard/Charts/Column';
import CategoryTable from '../../components/Dashboard/CategoryTable';
import SalesmanTable from '../../components/Dashboard/SalesmanTable';
const dateFormat = 'YYYY/MM/DD';
const {RangePicker} = DatePicker;
const {TabPane} = Tabs;
const {Option} = Select;
const topColResponsiveProps = {xs: 24, sm: 12, md: 12, lg: 12, xl: 8, style: {marginBottom: 24}};

const Dashboard = () => {
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  return (
    <Content>
      <div style={{position: 'relative', pointerEvents: 'none'}}>
        <div
          style={{
            background: 'white',
            padding: '24px 32px',
            position: 'absolute',
            zIndex: 200,
            left: '50%',
            top: '40vh',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <Typography.Title level={3} style={{marginBottom: '0'}}>
            Dashboard features under development
          </Typography.Title>
        </div>
        <div style={{opacity: '.3'}}>
          <Row justify="space-between">
            <Col md={14} lg={16} xl={20}>
              <Row gutter={10}>
                <Col className={style['select-box-col']}>
                  <Select
                    defaultValue=" Country"
                    className={style.select_box}
                    onChange={handleChange}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </Col>
                <Col className={style['select-box-col']}>
                  <Select
                    defaultValue=" Region"
                    className={style.select_box}
                    onChange={handleChange}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </Col>
                <Col className={style['select-box-col']}>
                  <Select
                    defaultValue=" Salesman"
                    className={style.select_box}
                    onChange={handleChange}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="disabled" disabled>
                      Disabled
                    </Option>
                    <Option value="Yiminghe">yiminghe</Option>
                  </Select>
                </Col>
                <Col className={style['select-box-col']}>
                  <DatePicker defaultValue={moment('2015/01/01', dateFormat)} format={dateFormat} />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button className={style['print-button']}>
                <PrinterOutlined />
                Print
              </Button>
            </Col>
          </Row>
          <Row gutter={24} style={{marginTop: '75px'}}>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-blue']}>
                <Row justify="space-between" className={style['row-style']} align="middle">
                  <Col className={style['row-main-title']}>Total Sales</Col>
                  <Col className={style['row-main-title-blue']}>20.00</Col>
                </Row>
                <Divider />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Cash</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress percent={30} size="small" strokeColor="#27AE65" />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Temp. Credit</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress percent={50} size="small" strokeColor="#FF9100" />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Credit</Col>
                  <Col className={style['row-title-bold']}>50.0000</Col>
                </Row>
                <Progress percent={20} size="small" strokeColor="#F43A00" />
              </Card>
            </Col>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-violet']}>
                <Row justify="space-between" className={style['row-style']} align="middle">
                  <Col className={style['row-main-title']}>Total Collection</Col>
                  <Col className={style['row-main-title-violet']}>20.0000</Col>
                </Row>
                <Divider />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Cash</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress percent={30} size="small" strokeColor="#27AE65" />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Temp. Credit</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress percent={50} size="small" strokeColor="#F43A00" />
              </Card>
            </Col>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-green']}>
                <Row justify="space-between" className={style['row-style']} align="middle">
                  <Col className={style['row-main-title']}>Total Settlement</Col>
                  <Col className={style['row-main-title-green']}>20.0000</Col>
                </Row>
                <Divider />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Branch</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress size="small" percent={50} strokeColor="#344E41" />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Bank</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress percent={50} size="small" strokeColor="#344E41" />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-dark-blue']}>
                <Row justify="space-between" className={style['row-style']} align="middle">
                  <Col className={style['row-main-title']}>Total Credit Given</Col>
                  <Col className={style['row-main-title-dark-blue']}>20.0000</Col>
                </Row>
                <Divider />
                <Row justify="space-between" className={style['row-style']}>
                  <Col className={style['row-title']}>Collected</Col>
                  <Col className={style['row-title-bold']}>10.0000</Col>
                </Row>
                <Progress size="small" percent={50} strokeColor="#084087" />
              </Card>
            </Col>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-dark-blue']}>
                <Row>
                  <Col span={19}>
                    <p className={style['row-main-title']}>New Customer Added</p>
                  </Col>
                  <Col span={5}>
                    <img src={new_customer} width="50px" height="50px" alt="" />
                  </Col>
                </Row>
                <Row>
                  <p className={style['row-main-title-dark-blue']}>555</p>
                </Row>
                <Row>
                  <Col span={2}>
                    <img src={status} alt="" />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <p style={{color: '#27AE65'}}>+12</p>
                      <p> This Week</p>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col {...topColResponsiveProps}>
              <Card className={style['card-design-dark-blue']}>
                <Row align="bottom" justify="space-between">
                  <Col>
                    <p className={style['row-main-title']}>Total Salesman</p>
                  </Col>
                  <Col>
                    <img src={salesmen} width="50px" height="50px" alt="" />
                  </Col>
                </Row>

                <Row>
                  <p className={style['row-main-title-dark-blue']}>125</p>
                </Row>
                <Row>
                  <Col span={2}>
                    <img src={status} alt="" />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <p style={{color: '#27AE65'}}>+1</p>
                      <p> This Week</p>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* <Divider />
                <Row justify='space-between'>
                    <Col md={14} lg={16} xl={20}>
                        <Row gutter={20}>
                            <Col className={style['select-box-col']}>
                                <Select
                                    defaultValue=' Country'
                                    className={style.select_box}
                                    onChange={handleChange}
                                >
                                    <Option value='jack'>Jack</Option>
                                    <Option value='lucy'>Lucy</Option>
                                    <Option value='disabled' disabled>
                                        Disabled
                                    </Option>
                                    <Option value='Yiminghe'>yiminghe</Option>
                                </Select>
                            </Col>
                            <Col className={style['select-box-col']}>
                                <Select
                                    defaultValue=' Region'
                                    className={style.select_box}
                                    onChange={handleChange}
                                >
                                    <Option value='jack'>Jack</Option>
                                    <Option value='lucy'>Lucy</Option>
                                    <Option value='disabled' disabled>
                                        Disabled
                                    </Option>
                                    <Option value='Yiminghe'>yiminghe</Option>
                                </Select>
                            </Col>
                            <Col className={style['select-box-col']}>
                                <Select
                                    defaultValue=' Salesman'
                                    className={style.select_box}
                                    onChange={handleChange}
                                >
                                    <Option value='jack'>Jack</Option>
                                    <Option value='lucy'>Lucy</Option>
                                    <Option value='disabled' disabled>
                                        Disabled
                                    </Option>
                                    <Option value='Yiminghe'>yiminghe</Option>
                                </Select>
                            </Col>
                            <Col className={style['select-box-col']}>
                                <RangePicker
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className={style['select-box-col']}>
                        <Select
                            defaultValue=' Compare'
                            className={style.select_box}
                            onChange={handleChange}
                        >
                            <Option value='jack'>Jack</Option>
                            <Option value='lucy'>Lucy</Option>
                        </Select>
                    </Col>
                </Row>
                <Row
                    {...topColResponsiveProps}
                    className={style['graph-style']}
                >
                    <Tabs defaultActiveKey='1' style={{ width: '100%' }}>
                        <TabPane tab='Sales' key='1'>
                            <div
                                style={{
                                    height: '500px',
                                    margin: '30px',
                                }}
                            >
                                <Column />
                            </div>
                        </TabPane>
                        <TabPane tab='Collection' key='2'>
                            <div
                                style={{
                                    height: '500px',
                                    margin: '30px',
                                }}
                            >
                                <Column />
                            </div>
                        </TabPane>
                        <TabPane tab='Settlement' key='3'>
                            <div
                                style={{
                                    height: '500px',
                                    margin: '30px',
                                }}
                            >
                                <Column />
                            </div>
                        </TabPane>
                        <TabPane tab='Credit' key='4'>
                            <div
                                style={{
                                    height: '500px',
                                    margin: '30px',
                                }}
                            >
                                <Column />
                            </div>
                        </TabPane>
                        <TabPane tab='Store Visited' key='5'>
                            <div
                                style={{
                                    height: '500px',
                                    margin: '30px',
                                }}
                            >
                                <Column />
                            </div>
                        </TabPane>
                    </Tabs>
                </Row>
                <Divider />
                <Row justify='space-between'>
                    <Col lg={12} className={style['table-style']}>
                        <h2 className={style['table-title']}>
                            Top 10 Salesmen
                        </h2>
                        <SalesmanTable />
                    </Col>
                    <Col lg={12} className={style['table-style']}>
                        <h2 className={style['table-title']}>
                            Top 10 Categories
                        </h2>
                        <CategoryTable />
                    </Col>
                </Row> */}
        </div>
      </div>
    </Content>
  );
};
export default Dashboard;
