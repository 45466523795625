import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Drawer, message, Col, Row, DatePicker } from 'antd';
import { postApiWithAuthToken, getApiWithAuthToken, getApiWithPublicAuthToken, updateApiWithAuthToken } from '../../../api';

const CustomerTypesForm = ({ handleDrawerClose, isDrawerVisible, getCustomerTypesList, customerTypes }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [customerTypes]);

    // Form Submit 

    const handleSubmit = () => {
        form.submit();
    };

    const onFinish = (values) => {
        message.loading({ content: 'Processing...', key: 'form' });

        if (customerTypes.id) {
            updateApiWithAuthToken(`/api/v1/admin/customerTypes/${customerTypes.id}`, values).then((res) => {
                if (!res.error) {
                    setTimeout(() => { message.success({ content: res.msg, key: 'form' }); }, 500);
                    handleDrawerClose();
                    getCustomerTypesList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        } else {
            postApiWithAuthToken('/api/v1/admin/customerTypes', values).then((res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success({ content: res.msg, key: 'form' });
                    }, 500);
                    handleDrawerClose();
                    getCustomerTypesList();
                } else {
                    setTimeout(() => {
                        if (res.errors.length) {
                            res.errors.map((each) => {
                                message.error({ content: each.msg, key: 'form' });
                                return null;
                            });
                        } else {
                            message.error({ content: res.errors, key: 'form' });
                        }
                    }, 500);
                }
            });
        }
    };

    return (
        <Drawer
            title={customerTypes.id ? "Edit customer types" : "Add customer types"}
            width={480}
            onClose={handleDrawerClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="primary">
                        Submit
                    </Button>
                </div>
            }
        >
            <Form layout="vertical"
                hideRequiredMark
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish} >
                <Form.Item
                    name="name"
                    label="Name"
                    initialValue={customerTypes.name}
                    rules={[{ required: true, message: 'This field is required' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Drawer >
    );
};
export default CustomerTypesForm;
