import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Table, Row, Space, message, Popconfirm, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken, deleteApiWithAuthToken, } from '../../../api';
import BranchForm from './BranchForm';

const Branch = () => {

    const [branchList, setBranchList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getBranchList = () => {
        getApiWithAuthToken('/api/v1/admin/branches').then((res) => {
            if (!res.error && res.data.length) {
                setBranchList([]);
                res.data.map((each) =>
                    setBranchList((branchList) => [
                        ...branchList,
                        {
                            id: each.id,
                            key: each.id,
                            code: each.code,
                            name: each.name,
                            manager: each.manager,
                            lang: each.lang,
                            currency: each.currency,
                            country: each.country,
                            region: each.region,
                            area: each.area,
                            address: each.address,
                        },
                    ])
                );
            } else {
                if (res.errors) {
                    Object.values(res.errors).map((each) =>
                        message.error(each.msg)
                    );
                    setBranchList([]);
                } else {
                    if (res.errors) {
                        message.error(res.errors);
                        setBranchList([]);
                    } else {
                        message.error({ content: 'Network Error!' });
                        setBranchList([]);
                    }
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        getBranchList();
    }, []);

    // Delete function 
    function handleDeleteClick(id) {
        message.loading({ content: 'Processing...', key: id });
        deleteApiWithAuthToken(`/api/v1/admin/branches/${id}`).then((res) => {
            message.loading({ content: 'Loading...', duration: 0.5 });
            if (res.error) {
                if (res.errors.errno === 1451) {
                    message.error({
                        content:
                            'Could not delete!, The branch is being referred in another place',
                        key: id
                    });
                } else {
                    message.error({
                        content: res.errors.sqlMessage,
                        key: id
                    });
                }
            } else {
                message.success({
                    content: 'Deleted!',
                    key: id
                });
                getBranchList();
            }
        });
    }

    // Edit function 

    const [selectedBranch, setSelectedBranch] = useState({});
    const handleEditClick = (record) => {
        setSelectedBranch(record);
        setIsDrawerVisible(true);
    };

    //Drawer Open function
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const showDrawer = () => {
        setSelectedBranch({});
        setIsDrawerVisible(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerVisible(false);
    };

    // Table functions 

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    var searchInput = useRef(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
            sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),

        },
        {
            title: 'Manager',
            dataIndex: 'manager',
            key: 'manager',
            ...getColumnSearchProps('manager'),
            sorter: (a, b) => (a.manager ?? '').localeCompare(b.manager ?? ''),

        },
        {
            title: 'Language',
            dataIndex: 'lang',
            key: 'lang',
            ...getColumnSearchProps('lang'),
            sorter: (a, b) => (a.lang ?? '').localeCompare(b.lang ?? ''),

        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            ...getColumnSearchProps('currency'),
            sorter: (a, b) => (a.currency ?? '').localeCompare(b.currency ?? ''),

        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            ...getColumnSearchProps('country'),
            sorter: (a, b) => (a.country ?? '').localeCompare(b.country ?? ''),

        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            ...getColumnSearchProps('region'),
            sorter: (a, b) => (a.region ?? '').localeCompare(b.region ?? ''),

        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            ...getColumnSearchProps('area'),
            sorter: (a, b) => (a.area ?? '').localeCompare(b.area ?? ''),

        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (_, record) => (
                <>
                    <Button size='small' onClick={() => handleEditClick(record)} >
                        Edit
                    </Button> <span> </span>
                    <Popconfirm
                        title='Are you sure to delete this branch?'
                        onConfirm={() => handleDeleteClick(record.id)}
                    >
                        <Button danger size='small'>
                            Delete
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <Row justify='space-between' style={{ margin: "24px 0" }}>
                <Typography.Title level={3} style={{ marginBottom: "0" }}>Branch</Typography.Title>
                <Button type='primary' onClick={showDrawer} >
                    Add
                </Button>
            </Row>
            <Table  scroll={{ x: 0 }} dataSource={branchList} columns={columns} loading={loading} />
            <BranchForm handleDrawerClose={handleDrawerClose} isDrawerVisible={isDrawerVisible} getBranchList={getBranchList} branch={selectedBranch} />
        </>);

};
export default Branch;
