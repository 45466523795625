import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Col, Row, DatePicker} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const AreaForm = ({handleDrawerClose, isDrawerVisible, getAreaList, area}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [managerList, setManagerList] = useState([]);
  const [regionList, setRegionList] = useState([]);

  function getManagerList() {
    getApiWithAuthToken('/api/v1/admin/managers').then(res => {
      if (res.data && res.data.length > 0) {
        setManagerList(res.data);
      }
    });
  }
  function getRegionList() {
    getApiWithAuthToken('/api/v1/admin/regions').then(res => {
      if (res.data && res.data.length > 0) {
        setRegionList(res.data);
      }
    });
  }

  useEffect(() => {
    getManagerList();
    getRegionList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [area]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    if (area.id) {
      updateApiWithAuthToken(`/api/v1/admin/areas/${area.id}`, values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getAreaList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    } else {
      postApiWithAuthToken('/api/v1/admin/areas', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getAreaList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({
                  content: each.msg,
                  key: 'form',
                });
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={area.id ? 'Edit an area' : 'Add a new area'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
        scrollToFirstError={true}
      >
        <Form.Item
          name="name"
          label="Name"
          initialValue={area.name}
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ref_code" label="Ref Code" initialValue={area.ref_code}>
          <Input />
        </Form.Item>
        <Form.Item name="prefix" label="Prefix" initialValue={area.prefix}>
          <Input />
        </Form.Item>
        <Form.Item
          name="manager"
          label="Manager"
          initialValue={
            managerList.find(manager => manager.name === area.manager)
              ? managerList.find(manager => manager.name === area.manager).id
              : null
          }
        >
          <Select
            showSearch
            placeholder="Select a manager"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {managerList.length &&
              managerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          initialValue={
            regionList.find(region => region.name === area.region)
              ? regionList.find(region => region.name === area.region).id
              : null
          }
        >
          <Select
            showSearch
            placeholder="Select a region"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {regionList.length &&
              regionList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default AreaForm;
