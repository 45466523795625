import React, {useState, useEffect} from 'react';
import {Form, Input, InputNumber, Select, Button, Drawer, message} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';

const RouteLastCodeForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getRouteLastCodeList,
  routeLastCode,
}) => {
  const [form] = Form.useForm();

  // Get dropdown data
  const [routeList, setRouteList] = useState([]);

  function getRouteList() {
    getApiWithPublicAuthToken('/api/v1/admin/routes').then(res => {
      if (res.data && res.data.length > 0) {
        setRouteList(res.data);
      }
    });
  }

  useEffect(() => {
    getRouteList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [routeLastCode]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});
    if (routeLastCode.id) {
      updateApiWithAuthToken(`/api/v1/admin/routeLastCodes/${routeLastCode.id}`, values).then(
        res => {
          if (!res.error) {
            setTimeout(() => {
              message.success({content: res.msg, key: 'form'});
            }, 500);
            handleDrawerClose();
            getRouteLastCodeList();
          } else {
            setTimeout(() => {
              if (res.errors.length) {
                res.errors.map(each => {
                  message.error({content: each.msg, key: 'form'});
                  return null;
                });
              } else {
                message.error({content: res.errors, key: 'form'});
              }
            }, 500);
          }
        }
      );
    } else {
      postApiWithAuthToken('/api/v1/admin/routeLastCodes', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getRouteLastCodeList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={routeLastCode.id ? 'Edit route last code' : 'Add route last code'}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
        <Form.Item name="route_id" label="Route" initialValue={routeLastCode.route_id}>
          <Select showSearch placeholder="Select a route" optionFilterProp="children">
            {routeList?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sales" label="Sales" initialValue={routeLastCode.sales}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="collection" label="Collection" initialValue={routeLastCode.collection}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="advance_collection"
          label="Advance Collection"
          initialValue={routeLastCode.advance_collection}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="load_code" label="Load" initialValue={routeLastCode.load_code}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="unload" label="Unload" initialValue={routeLastCode.unload}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="settlements" label="Settlements" initialValue={routeLastCode.settlements}>
          <InputNumber />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default RouteLastCodeForm;
