import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
    Form,
    Input,
    Button,
    Table,
    Row,
    Col,
    Space,
    Popconfirm,
    message,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
    getApiWithAuthToken,
    deleteApiWithAuthToken,
    updateApiWithAuthToken,
} from '../../../api';
const PaymentTypeAdd = lazy(() => import('./PaymentTypeAdd'));
const PaymentType = () => {
    const [form] = Form.useForm();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = inputType === 'text' ? <Input /> : <Input />;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };
    const isEditing = (record) => record.id === editingKey;

    const handleEditClick = (record) => {
        console.log(record);
        getApiWithAuthToken(`/api/v1/admin/languages/${record.id}`).then(
            (res) => {
                if (res.data) {
                    console.log(res.data);
                    form.setFieldsValue({
                        id: res.data[0].id,
                        code: res.data[0].code,
                        name: res.data[0].name,
                    });
                    setEditingKey(record.id);
                }
            }
        );
    };

    const cancel = () => {
        setEditingKey('');
    };
    const handleSaveClick = async (key) => {
        const row = await form.validateFields();
        console.log(row);
        const id = key;
        updateApiWithAuthToken(`/api/v1/admin/languages/${id}`, row).then(
            (res) => {
                console.log(res);
                if (!res.error) {
                    setTimeout(() => {
                        message.success(res.msg);
                    }, 500);
                    setEditingKey('');
                    getPaymentTypeList();
                } else {
                    setTimeout(() => {
                        message.error(res.errors);
                    }, 500);
                }
            }
        );
    };
    useEffect(() => {
        getPaymentTypeList();
    }, []);
    // To Get all Table Data
    const getPaymentTypeList = () => {
        getApiWithAuthToken('http://localhost:5000/api/v1/admin/').then(
            (res) => {
                console.log(res);
                if (!res.error && res.data && res.data.length) {
                    setPaymentTypeList([]);
                    res.data.map((each) =>
                        setPaymentTypeList((paymentTypeList) => [
                            ...paymentTypeList,
                            {
                                id: each.id,
                                name: each.name,
                                prefix: each.prefix,
                                trans_id: each.trans_id,
                                lang: each.lang,
                                region: each.region,
                            },
                        ])
                    );
                } else {
                    if (res.errors) {
                        Object.values(res.errors).map((each) =>
                            message.error(each.msg)
                        );
                        setPaymentTypeList([]);
                    } else {
                        if (res.errors) {
                            Object.values(res.errors).map((each) =>
                                message.error(each.msg)
                            );
                            setPaymentTypeList([]);
                        } else {
                            if (res.errors) {
                                message.error(res.errors);
                                setPaymentTypeList([]);
                            } else {
                                message.error({ content: 'Network Error!' });
                                setPaymentTypeList([]);
                            }
                        }
                    }
                }
            }
        );
    };
    //Delete
    function handleDeleteClick(id) {
        deleteApiWithAuthToken(
            `http://localhost:5000/api/v1/admin//${id}`
        ).then((res) => {
            console.log(res);
            message.loading({ content: 'Loading...', duration: 0.5 });
            if (res.error) {
                setTimeout(() => {
                    message.success({
                        content: 'Not Deleted!',
                        duration: 2,
                    });
                }, 1000);
            } else {
                setTimeout(() => {
                    message.success({
                        content: 'Deleted!',
                        duration: 2,
                    });
                }, 1000);
                getPaymentTypeList();
            }
        });
    }
    //Drawer Open function
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const showDrawerVisible = () => {
        showDrawer();
        form.resetFields();
    };
    //  Drawer Close function
    const handleCancel = () => {
        setIsDrawerVisible(false);
    };
    /* Table Functions started */
    const [searchText, setSearchText] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        // ref={node => {
                        //   this.searchInput = node;
                        // }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() =>
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    // setTimeout(() => this.searchInput.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const columns = [
        {
            title: ' Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'table-col-capitalize',
            editable: true,
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Edit',
            key: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            type='link'
                            onClick={() => handleSaveClick(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Button>
                        <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                            <Button type='link'>Cancel</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button
                        size='small'
                        disabled={editingKey !== ''}
                        onClick={() => handleEditClick(record)}
                    >
                        Edit
                    </Button>
                );
            },
        },
        {
            title: 'Delete',
            key: 'operation',
            render: (_, record) => (
                <Popconfirm
                    title='Are you sure to delete this Payment Type?'
                    onConfirm={() => handleDeleteClick(record.id)}
                >
                    <Button danger size='small'>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    /* Table Functions end */
    /*editable column seting function started*/
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    /*editable column seting function end*/
    return (
        <div>
            <Suspense fallback={<h1>Still Loading…</h1>}>
                <PaymentTypeAdd
                    setIsDrawerVisible={setIsDrawerVisible}
                    isDrawerVisible={isDrawerVisible}
                    handleCancel={handleCancel}
                />
            </Suspense>
            <Row style={{ margin: '2%' }}>
                <Col span={24}>
                    <Table
                        title={() => (
                            <Row justify='space-between'>
                                <h1 style={{ fontWeight: 'bold' }}>
                                    PAYMENT TYPE
                                </h1>
                                <Button
                                    type='primary'
                                    onClick={showDrawerVisible}
                                >
                                    ADD
                                </Button>
                            </Row>
                        )}
                        bordered
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        columns={mergedColumns}
                        dataSource={paymentTypeList}
                        scroll={{ x: 1500 }}
                        size='small'
                        rowClassName='editable-row'
                    />
                </Col>
            </Row>
        </div>
    );
};
export default PaymentType;
