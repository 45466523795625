import React from 'react';
import SalesDetailsTable from '../../../components/Transactions/Sales/SalesDetailsTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <SalesDetailsTable />
        </ContentView>
    );
};
export default index;
