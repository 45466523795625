import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,
} from '../actions/types';
const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: false,
    error: null,
    user: null,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                error: null,
                token: localStorage.getItem('token'),
                user: action.payload.data,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.data);
            return {
                ...state,
                token: action.payload.data,
                isAuthenticated: true,
                loading: false,
                error: null,
            };
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload,
            };
        default:
            return state;
    }
};
