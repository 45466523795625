import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
    Form,
    Input,
    Button,
    Table,
    Row,
    Col,
    Space,
    Popconfirm,
    message,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getApiWithAuthToken, deleteApiWithAuthToken } from '../../../api';
const SalesmanPriceAdd = lazy(() => import('./SalesmanPriceAdd'));
const SalesmanPrice = () => {
    const [form] = Form.useForm();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [updateId, setUpdateId] = useState('');
    const [salesmanPriceList, setSalesmanPriceList] = useState([]);
    useEffect(() => {
        getsalesmanPriceList();
    }, []);
    // To Get all Table Data
    const getsalesmanPriceList = () => {
        getApiWithAuthToken('').then((res) => {
            console.log(res);
            if (!res.error && res.data && res.data.length) {
                setSalesmanPriceList([]);
                res.data.map((each) =>
                    setSalesmanPriceList((salesmanPriceList) => [
                        ...salesmanPriceList,
                        {
                            id: each.id,
                            name: each.name,
                            prefix: each.prefix,
                            trans_id: each.trans_id,
                            lang: each.lang,
                            region: each.region,
                        },
                    ])
                );
            } else {
                if (res.errors) {
                    Object.values(res.errors).map((each) =>
                        message.error(each.msg)
                    );
                    setSalesmanPriceList([]);
                } else {
                    if (res.errors) {
                        message.error(res.errors);
                        setSalesmanPriceList([]);
                    } else {
                        message.error({ content: 'Network Error!' });
                        setSalesmanPriceList([]);
                    }
                }
            }
        });
    };
    //Delete
    function handleDeleteClick(id) {
        deleteApiWithAuthToken(
            `http://localhost:5000/api/v1/admin//${id}`
        ).then((res) => {
            console.log(res);
            message.loading({ content: 'Loading...', duration: 0.5 });
            if (res.error) {
                setTimeout(() => {
                    if (res.errors.errno === 1451) {
                        message.error({
                            content:
                                'Could not delete!, The company is being referred in another place',
                            duration: 2,
                        });
                    } else {
                        message.error({
                            content: res.errors.sqlMessage,
                            duration: 2,
                        });
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    message.success({
                        content: 'Deleted!',
                        duration: 2,
                    });
                }, 1000);
                getsalesmanPriceList();
            }
        });
    }
    //Get data by Id
    function handleEditClick(id) {
        // showDrawer();
        setIsDrawerVisible(true);
        getApiWithAuthToken(`http://localhost:5000/api/v1/admin//${id}`).then(
            (res) => {
                if (res.data) {
                    console.log(res.data);
                    setUpdateId(res.data.id);
                    form.setFieldsValue({
                        id: res.data.id,
                        name: res.data.name,
                        prefix: res.data.prefix,
                        trans_id: res.data.trans_id,
                        lang: res.data.lang_id,
                        region: res.data.region_id,
                    });
                }
            }
        );
    }

    //Drawer Open function
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const showDrawerVisible = () => {
        showDrawer();
        form.resetFields();
    };
    //  Drawer Close function
    const handleCancel = () => {
        setIsDrawerVisible(false);
    };
    /* Table Functions started */
    const [searchText, setSearchText] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        // ref={node => {
                        //   this.searchInput = node;
                        // }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() =>
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    // setTimeout(() => this.searchInput.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const columns = [
        {
            title: ' Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
            sorter: (a, b) => a.code - b.code,
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            ...getColumnSearchProps('product_name'),
            sorter: (a, b) => a.product_name.localeCompare(b.product_name),
        },
        {
            title: 'Salesman',
            dataIndex: 'salesman',
            key: 'salesman',
            ...getColumnSearchProps('salesman'),
            sorter: (a, b) => a.salesman.localeCompare(b.salesman),
        },
        {
            title: 'Price Key',
            dataIndex: 'price_key',
            key: 'price_key',
            ...getColumnSearchProps('price_key'),
            sorter: (a, b) => a.price_key.localeCompare(b.price_key),
        },
        {
            title: 'From date',
            dataIndex: 'from_date',
            key: 'from_date',
            ...getColumnSearchProps('from_date'),
            sorter: (a, b) => new Date(a.from_date) - new Date(b.from_date),
        },
        {
            title: 'To Date',
            dataIndex: 'to_date',
            key: 'to_date',
            ...getColumnSearchProps('to_date'),
            sorter: (a, b) => new Date(a.to_date) - new Date(b.to_date),
        },
        {
            title: 'Edit',
            key: 'operation',
            render: (record) => (
                <Button
                    size='small'
                    onClick={() => {
                        handleEditClick(record.id);
                    }}
                >
                    Edit
                </Button>
            ),
        },
        {
            title: 'Delete',
            key: 'operation',
            render: (_, record) => (
                <Popconfirm
                    title='Are you sure to delete this Salesman Price?'
                    onConfirm={handleDeleteClick}
                >
                    <Button danger size='small'>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    /* Table Functions end */

    return (
        <div>
            <Suspense fallback={<h1>Still Loading…</h1>}>
                <SalesmanPriceAdd
                    setIsDrawerVisible={setIsDrawerVisible}
                    isDrawerVisible={isDrawerVisible}
                    form={form}
                    updateId={updateId}
                    handleCancel={handleCancel}
                />
            </Suspense>
            <Row style={{ margin: '2%' }}>
                <Col span={24}>
                    <Table
                        title={() => (
                            <Row justify='space-between'>
                                <h1 style={{ fontWeight: 'bold' }}>
                                    SALESMAN PRICE
                                </h1>
                                <Button
                                    type='primary'
                                    onClick={showDrawerVisible}
                                >
                                    ADD
                                </Button>
                            </Row>
                        )}
                        bordered
                        columns={columns}
                        dataSource={salesmanPriceList}
                        scroll={{ x: 1500 }}
                        size='small'
                    />
                </Col>
            </Row>
        </div>
    );
};
export default SalesmanPrice;
