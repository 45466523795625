import React from 'react';
import Collector from './Collector';
import ContentView from '../../Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <Collector />
        </ContentView>
    );
};
export default index;
