import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { login, loadUser } from '../../actions/authAction';
import login_art from '../../assets/images/login_art.png';
import vf_logo from '../../assets/images/VF_logo.png';
import style from './Login.module.scss';
// import { CustomValidation } from 'express-validator/src/context-items';

const Login = ({ login, loadUser, isAuthenticated }) => {
    const history = useHistory();
    const [email, setEmail] = useState(
        localStorage.email ? localStorage.email : ''
    );
    const [password, setPassword] = useState(
        localStorage.password ? localStorage.password : ''
    );

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        } else if (localStorage.email) {
            setEmail(email);
            setPassword(password);
        }
        if (window.localStorage.token) {
            (async () => {
                let result = await loadUser();
                if (result && result.data.errors) {
                    if (result.data.errors[0].msg) {
                        message.error(result.data.errors[0].msg);
                    } else {
                        message.error(result.data.errors);
                    }
                }
            })();
        }
    }, [isAuthenticated]);

    const onSubmit = (values) => {
        login(values).then((res) => {
            if (!res) {
                message.error('Server Error');
            } else if (res.data && res.data.errors) {
                message.error(res.data.errors[0].msg);
            } else if (values.remember) {
                localStorage.setItem('email', values.email);
                localStorage.setItem('password', values.password);
            }
        });
    };

    return (
        <div>
            <Row
                justify='center'
                align='middle'
                style={{ position: 'relative', height: '100vh', padding: '20px' }} >
                <img
                    style={{
                        width: '197px',
                        position: 'absolute',
                        top: '26px',
                        left: '22px',
                        zIndex: 5,
                    }}
                    src={vf_logo}
                    alt='Vanforces' />

                <Col lg={10} md={12} sm={20}>
                    <img style={{ width: '100%' }} src={login_art} alt='login artwork' />
                </Col>

                <Col
                    lg={10}
                    sm={24}
                    style={{ display: 'flex', placeContent: 'center' }} >
                    <Form
                        name='normal_login'
                        initialValues={{
                            remember: true,
                            email: email,
                            password: password,
                        }}
                        onFinish={onSubmit}  >
                        <h1 style={{ textAlign: 'center' }}>Login to VanForces</h1>
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}   >
                            <Input
                                prefix={<UserOutlined className='site-form-item-icon' />}
                                placeholder='Email ID'
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className='site-form-item-icon' />}
                                type='password'
                                placeholder='Password'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name='remember' valuePropName='checked' noStyle>
                                <Checkbox style={{ textAlign: 'left' }}>Remember me</Checkbox>
                            </Form.Item>

                            <a className='login-form-forgot' href='!#'>
                                Forgot password
                            </a>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ width: '100%' }}
                            >
                                Log in
                            </Button>
                        </Form.Item>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ color: '#aeb5b2' }}>
                                <ExclamationCircleFilled
                                    style={{
                                        color: '#c8cbce',
                                        marginRight: '8px'
                                    }}
                                />
                                Please subscribe to one of our plans to get login details
                            </p>
                            <a href='!#'> Click here to see our plans </a>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div >
    );
};
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
});
export default connect(mapStateToProps, { login, loadUser })(Login);
