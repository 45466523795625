import React from 'react';
import ManagerTable from '../../../components/UserMasters/Manager/ManagerTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <ManagerTable />
        </ContentView>
    );
};
export default index;
