import React, {useState, useEffect} from 'react';
import {Form, Input, Select, Button, Drawer, message, Col, InputNumber, DatePicker} from 'antd';
import {
  postApiWithAuthToken,
  getApiWithAuthToken,
  getApiWithPublicAuthToken,
  updateApiWithAuthToken,
} from '../../../api';
import moment from 'moment';

const CustomerPriceForm = ({
  handleDrawerClose,
  isDrawerVisible,
  getCustomerPriceList,
  customerPrice,
}) => {
  const [form] = Form.useForm();

  const [customerList, setCustomerList] = useState([]);
  const [priceKeyList, setPriceKeyList] = useState([]);

  function getCustomerList() {
    getApiWithPublicAuthToken('/api/v1/admin/customers').then(res => {
      if (res.data && res.data.length > 0) {
        setCustomerList(res.data);
      }
    });
  }
  function getPriceKeyList() {
    getApiWithAuthToken('/api/v1/admin/priceKeys').then(res => {
      if (res.data && res.data.length > 0) {
        setPriceKeyList(res.data);
      }
    });
  }

  useEffect(() => {
    getCustomerList();
    getPriceKeyList();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [customerPrice]);

  // Form Submit

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = values => {
    message.loading({content: 'Processing...', key: 'form'});

    if (customerPrice.id) {
      updateApiWithAuthToken(`/api/v1/admin/customerPrices/${customerPrice.id}`, values).then(
        res => {
          if (!res.error) {
            setTimeout(() => {
              message.success({content: res.msg, key: 'form'});
            }, 500);
            handleDrawerClose();
            getCustomerPriceList();
          } else {
            setTimeout(() => {
              if (res.errors.length) {
                res.errors.map(each => {
                  message.error({content: each.msg, key: 'form'});
                  return null;
                });
              } else {
                message.error({content: res.errors, key: 'form'});
              }
            }, 500);
          }
        }
      );
    } else {
      postApiWithAuthToken('/api/v1/admin/customerPrices', values).then(res => {
        if (!res.error) {
          setTimeout(() => {
            message.success({content: res.msg, key: 'form'});
          }, 500);
          handleDrawerClose();
          getCustomerPriceList();
        } else {
          setTimeout(() => {
            if (res.errors.length) {
              res.errors.map(each => {
                message.error({content: each.msg, key: 'form'});
                return null;
              });
            } else {
              message.error({content: res.errors, key: 'form'});
            }
          }, 500);
        }
      });
    }
  };

  return (
    <Drawer
      title={customerPrice.id ? 'Edit customer price ' : 'Add customer price '}
      width={480}
      onClose={handleDrawerClose}
      visible={isDrawerVisible}
      bodyStyle={{paddingBottom: 80}}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleDrawerClose} style={{marginRight: 8}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{remember: true}}
        onFinish={onFinish}
      >
        <Form.Item name="ref_code" label="Ref Code" initialValue={customerPrice.ref_code}>
          <Input />
        </Form.Item>
        <Form.Item
          name="customer"
          label="Customer"
          initialValue={
            customerList.find(customer => customer.name === customerPrice.customer)
              ? customerList.find(customer => customer.name === customerPrice.customer).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select
            showSearch
            placeholder="Select a customer"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {customerList.length &&
              customerList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="price_key"
          label="Price key"
          initialValue={
            priceKeyList.find(priceKey => priceKey.description === customerPrice.price_key)
              ? priceKeyList.find(priceKey => priceKey.description === customerPrice.price_key).id
              : null
          }
          rules={[{required: true, message: 'This field is required'}]}
        >
          <Select
            showSearch
            placeholder="Select a price key"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {priceKeyList.length &&
              priceKeyList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="from_date"
          label="From date"
          initialValue={
            moment(customerPrice.from_date).isValid()
              ? moment.utc(customerPrice.from_date).local()
              : null
          }
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="to_date"
          label="To date"
          initialValue={
            moment(customerPrice.to_date).isValid()
              ? moment.utc(customerPrice.to_date).local()
              : null
          }
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="last_sold_price"
          label="Last sold price"
          initialValue={customerPrice.last_sold_price}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default CustomerPriceForm;
