import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography, Avatar} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, deleteApiWithAuthToken} from '../../../api';
import SalesmanForm from './SalesmanForm';
import toTitleCase from 'helpers/toTitleCase';

const Salesman = () => {
  const [salesmanList, setSalesmanList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSalesmanList = () => {
    getApiWithAuthToken('/api/v1/admin/salesmen').then(res => {
      if (!res.error && res.data?.length) {
        setSalesmanList(
          res.data.map(each => ({
            id: each.id,
            key: each.id,
            profile_image: each.profile_image,
            code: each.code,
            name: each.name,
            email: each.email,
            lang: toTitleCase(each.lang),
            lang_id: each.lang_id,
            branch: each.branch,
            job_title: each.job_title,
            def_route: each.def_route,
            temp_credit_amt: each.temp_credit_amt,
            temp_credit_days: each.temp_credit_days,
            cash_in_hand: each.cash_in_hand,
            credit_given: each.credit_given,
            temp_credit_given: each.temp_credit_given,
            visit_target: each.visit_target,
            credit_sales_target: each.credit_sales_target,
            cash_sales_target: each.cash_sales_target,
            collection_target: each.collection_target,
            enable_edit_price: each.enable_edit_price,
            enable_discount: each.enable_discount,
            // enable_neg_sales: each.enable_neg_sales,
            enable_void_option: each.enable_void_option,
            discount_in_collection: each.discount_in_collection,
            trans_mods: each.trans_mods,
            show_rjp_customers_only: each.show_rjp_customers_only,
            max_discount_perc: each.max_discount_perc,
            discount_in_amount: each.discount_in_amount,
            enable_line_item_discount: each.enable_line_item_discount,
            select_invoice_in_collection: each.select_invoice_in_collection,
            enable_simplified_tax: each.enable_simplified_tax,
            allow_below_cost_price_sales: each.allow_below_cost_price_sales,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setSalesmanList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSalesmanList();
  }, []);

  // Delete function
  function handleDeleteClick(id) {
    message.loading({content: 'Processing...', key: id});
    deleteApiWithAuthToken(`/api/v1/admin/salesmen/${id}`).then(res => {
      if (res.error) {
        setTimeout(() => {
          if (res.errors.errno === 1451) {
            message.error({
              content: 'Could not delete!, The salesman is being referred in another place',
              key: id,
            });
          } else {
            message.error({
              content: res.errors.sqlMessage,
              key: id,
            });
          }
        }, 1000);
      } else {
        setTimeout(() => {
          message.success({
            content: 'Deleted!',
            key: id,
          });
        }, 1000);
        getSalesmanList();
      }
    });
  }

  // Edit function

  const [selectedSalesman, setSelectedSalesman] = useState({});
  const handleEditClick = record => {
    setSelectedSalesman(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedSalesman({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Photo',
      key: 'photo',
      render: (_, record) => <Avatar src={record.profile_image} />,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'lang',
      ...getColumnSearchProps('lang'),
      sorter: (a, b) => (a.lang ?? '').localeCompare(b.lang ?? ''),
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
      ...getColumnSearchProps('job_title'),
      sorter: (a, b) => (a.job_title ?? '').localeCompare(b.job_title ?? ''),
    },
    {
      title: 'Def Route',
      dataIndex: 'def_route',
      key: 'def_route',
      ...getColumnSearchProps('def_route'),
      sorter: (a, b) => (a.def_route ?? '').localeCompare(b.def_route ?? ''),
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      ...getColumnSearchProps('branch'),
      sorter: (a, b) => (a.branch ?? '').localeCompare(b.branch ?? ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>{' '}
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this salesman?"
            onConfirm={() => handleDeleteClick(record.id)}
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Salesman
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table dataSource={salesmanList} columns={columns} scroll={{x: 0}} loading={loading} />
      <SalesmanForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getSalesmanList={getSalesmanList}
        salesman={selectedSalesman}
      />
    </>
  );
};
export default Salesman;
