import React, {useState, useRef} from 'react';
import {Input, Button, Space, message} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {deleteApiWithAuthToken, updateApiWithAuthToken} from '../api';

// export const useColumnSearchProps = (dataIndex) => {
//     const [searchText, setSearchText] = useState([]);
//     const [searchedColumn, setSearchedColumn] = useState('');

//     function handleSearch(selectedKeys, confirm, dataIndex) {
//         confirm();
//         setSearchText(selectedKeys[0]);
//         setSearchedColumn(dataIndex);
//     }

//     function handleReset(clearFilters) {
//         clearFilters();
//         setSearchText('');
//     }

//     return {
//         filterDropdown: ({
//             setSelectedKeys,
//             selectedKeys,
//             confirm,
//             clearFilters,
//         }) => (
//             <div style={{ padding: 8 }}>
//                 <Input
//                     placeholder={`Search ${dataIndex}`}
//                     value={selectedKeys[0]}
//                     onChange={(e) =>
//                         setSelectedKeys(e.target.value ? [e.target.value] : [])
//                     }
//                     onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                     style={{
//                         width: 188,
//                         marginBottom: 8,
//                         display: 'block',
//                     }}
//                 />
//                 <Space>
//                     <Button
//                         type='primary'
//                         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                         icon={<SearchOutlined />}
//                         size='small'
//                         style={{ width: 90 }}
//                     >
//                         Search
//                     </Button>
//                     <Button
//                         onClick={() => handleReset(clearFilters)}
//                         size='small'
//                         style={{ width: 90 }}
//                     >
//                         Reset
//                     </Button>
//                 </Space>
//             </div>
//         ),
//         filterIcon: (filtered) => (
//             <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
//         ),
//         onFilter: (value, record) =>
//             record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
//         render: (text) =>
//             searchedColumn === dataIndex ? (
//                 <Highlighter
//                     highlightStyle={{
//                         backgroundColor: '#ffc069',
//                         padding: 0,
//                     }}
//                     searchWords={[searchText]}
//                     autoEscape
//                     textToHighlight={text.toString()}
//                 />
//             ) : (
//                 text
//             ),
//     };
// };

export const useColumnSearchProps2 = dataIndex => {
  const [searchText, setSearchText] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  };
};

export const handleDeleteClick = (id, url, getList) => {
  // deleteApiWithAuthToken(`${url + id}`).then(res => {
  //   message.loading({content: 'Loading...', duration: 0.5});
  //   if (res.error) {
  //     setTimeout(() => {
  //       message.success({
  //         content: 'Not Deleted!',
  //         duration: 2,
  //       });
  //     }, 1000);
  //   } else {
  //     setTimeout(() => {
  //       message.success({
  //         content: 'Deleted!',
  //         duration: 2,
  //       });
  //     }, 1000);
  //     getList();
  //   }
  // });

  message.loading({
    content: 'Processing...',
    key: id,
  });
  deleteApiWithAuthToken(url + id).then(res => {
    message.loading({
      content: 'Loading...',
      duration: 0.5,
    });
    if (res.error) {
      if (res.errors.errno === 1451) {
        message.error({
          content: 'Could not delete!, The data is being referred in another place',
          key: id,
        });
      } else {
        message.error({
          content: res.errors.sqlMessage,
          key: id,
        });
      }
    } else {
      message.success({
        content: 'Deleted!',
        key: id,
      });
      getList();
    }
  });
};

export const saveChanges = async (key, setEditingKey, form, url, getList) => {
  const row = await form.validateFields();
  const id = key;
  updateApiWithAuthToken(`${url + id}`, row).then(res => {
    if (!res.error) {
      setTimeout(() => {
        message.success(res.msg);
      }, 500);
      setEditingKey('');
      getList();
    } else {
      setTimeout(() => {
        message.error(res.errors);
      }, 500);
    }
  });
};

export const useColumnSearchProps = (dataIndex, queryParams, setQueryParams, getList) => {
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setQueryParams({...queryParams, [dataIndex]: selectedKeys[0]});
    getList({...queryParams, [dataIndex]: selectedKeys[0]});
  };

  const handleReset = (clearFilters, dataIndex) => {
    setQueryParams({...queryParams, [dataIndex]: null});
    getList({...queryParams, [dataIndex]: null});
    clearFilters();
  };

  var searchInput = useRef(null);

  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: queryParams[dataIndex] ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      queryParams.hasOwnProperty(dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[queryParams[dataIndex]]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  };
};

export const handleTableChange = (queryParams, setQueryParams, getList) => {
  return (pagination, filters, sorter) => {
    setQueryParams({
      ...queryParams,
      page: pagination.current,
      limit: pagination.pageSize,
      field: sorter.field,
      order: sorter.order,
    });
    getList({
      ...queryParams,
      page: pagination.current,
      limit: pagination.pageSize,
      field: sorter.field,
      order: sorter.order,
    });
  };
};
