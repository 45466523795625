import React, {useState, useEffect, useRef} from 'react';
import {
  Input,
  Button,
  Table,
  Row,
  Space,
  message,
  Descriptions,
  Typography,
  DatePicker,
  Badge,
  Tag,
} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, ReloadOutlined} from '@ant-design/icons';
import {getApiWithAuthToken} from '../../../api';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

const Settlements = () => {
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [settlementsList, setSettlementsList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);

  const getSettlementsList = ({
    limit = 10,
    page = 1,
    code = null,
    collector_name = null,
    route_name = null,
    salesman_name = null,
    field = null,
    order = null,
  }) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/settlements?limit=${limit}&page=${page}` +
        (startDate == null ? '' : '&start_date=' + startDate) +
        (endDate == null ? '' : '&end_date=' + endDate) +
        (code == null ? '' : '&code=' + code) +
        (collector_name == null ? '' : '&collector=' + collector_name) +
        (route_name == null ? '' : '&route=' + route_name) +
        (salesman_name == null ? '' : '&salesman=' + salesman_name) +
        (field == null ? '' : '&field=' + field) +
        (order == null ? '' : '&order=' + order)
    ).then(res => {
      setNewStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
      setNewEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      if (!res.error && res.data.settlements.length) {
        setTotalCount(res.data.total_count);
        // setStartDate(moment(res.data.start_date).format("YYYY-MM-DD"));
        // setEndDate(moment(res.data.end_date).format("YYYY-MM-DD"));
        setSettlementsList([]);
        res.data.settlements.map(each =>
          setSettlementsList(settlementsList => [
            ...settlementsList,
            {
              key: each.id,
              code: each.code,
              date: each.date,
              sod_code: each.sod_code,
              route_code: each.route_code,
              collector_code: each.collector_code,
              collector_name: each.collector_name,
              route_code: each.route_code,
              route_name: each.route_name,
              salesman_code: each.salesman_code,
              salesman_name: each.salesman_name,
              total_to_settle: each.total_to_settle,
              total_settled: each.total_settled,
              balance: each.balance,
              currency: each.currency,
              payment_type: each.payment_type,
              payment_type_name: each.payment_type_name,
              status: each.status,
              void: each.void,
            },
          ])
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setSettlementsList([]);
        } else {
          if (res.errors) {
            message.error(res.errors);
            setSettlementsList([]);
          } else {
            message.error({content: 'Network Error!'});
            setSettlementsList([]);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getSettlementsList({limit: pageSize, page: currentPage});
  }, [startDate, endDate]);

  // Table functions

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  var searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex} `}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setCurrentPage(1);
    getSettlementsList({limit: pageSize, page: currentPage, [dataIndex]: selectedKeys[0]});
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    getSettlementsList({limit: pageSize, page: 1});
    clearFilters();
    setSearchText('');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getSettlementsList({
      pageSize: pagination.pageSize,
      page: pagination.current,
      invoice_no: filters.invoice_no,
      customer_name: filters.customer_name,
      route_name: filters.route_name,
      salesman_name: filters.salesman_name,
      field: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a, b) => (a.code ?? '').localeCompare(b.code ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      sorter: (a, b) => (a.date ?? '').localeCompare(b.date ?? ''),
    },
    {
      title: 'Total to Settle',
      dataIndex: 'total_to_settle',
      key: 'total_to_settle',
      sorter: (a, b) => (a.total_to_settle ?? '').localeCompare(b.total_to_settle ?? ''),
    },
    {
      title: 'Total Settled',
      dataIndex: 'total_settled',
      key: 'total_settled',
      sorter: (a, b) => (a.total_settled ?? '').localeCompare(b.total_settled ?? ''),
    },
    {
      title: 'Collector',
      dataIndex: 'collector_name',
      key: 'collector_name',
      ...getColumnSearchProps('collector_name'),
      sorter: (a, b) => (a.collector_name ?? '').localeCompare(b.collector_name ?? ''),
    },
    {
      title: 'Route',
      dataIndex: 'route_name',
      key: 'route_name',
      ...getColumnSearchProps('route_name'),
      sorter: (a, b) => (a.route_name ?? '').localeCompare(b.route_name ?? ''),
    },
    {
      title: 'Salesman',
      dataIndex: 'salesman_name',
      key: 'salesman_name',
      ...getColumnSearchProps('salesman_name'),
      sorter: (a, b) => (a.salesman_name ?? '').localeCompare(b.salesman_name ?? ''),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <>
          <Tag color={record.status == 'printed' ? 'green' : 'red'}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Settlements
        </Typography.Title>
        <div>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(
                moment(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7
                  )
                ).format('YYYY-MM-DD')
              );
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Last Week
          </Button>
          <Button
            type="primary"
            style={{marginRight: '16px'}}
            onClick={() => {
              setStartDate(moment(new Date()).format('YYYY-MM-DD'));
              setEndDate(moment(new Date()).format('YYYY-MM-DD'));
              setCurrentPage(1);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            value={[
              moment(newStartDate).isValid()
                ? moment.utc(newStartDate).local()
                : moment.utc(startDate).local(),
              moment(newEndDate).isValid()
                ? moment.utc(newEndDate).local()
                : moment.utc(endDate).local(),
            ]}
            onChange={(dates, dateStrings) => {
              setNewStartDate(dateStrings[0]);
              setNewEndDate(dateStrings[1]);
            }}
          />
          <Button
            type="primary"
            style={{marginLeft: '16px'}}
            onClick={() => {
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              setCurrentPage(1);
              // getSettlementsList(pageSize, 1);
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={settlementsList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: currentPage,
          size: 'default',
          pageSize: pageSize,
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
        expandable={{
          expandedRowRender: record => (
            <Descriptions bordered style={{backgroundColor: '#fff'}}>
              <Descriptions.Item label="Payment Type">
                {record.payment_type_name.charAt(0).toUpperCase() +
                  record.payment_type_name.slice(1)}
              </Descriptions.Item>
              {record.bank_ref_no > 0 ? (
                <Descriptions.Item label="Bank Ref. No.">{record.bank_ref_no}</Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Currency">
                {record.currency.charAt(0).toUpperCase() + record.currency.slice(1)}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Badge
                  status={record.status == 'printed' ? 'success' : 'error'}
                  text={record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                />
              </Descriptions.Item>
              {record.void ? (
                <Descriptions.Item label="Void">
                  <Badge status="error" text="Voided" />
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Balance">
                <Badge status={record.balance > 0 ? 'warning' : ''} text={record.balance} />
              </Descriptions.Item>
            </Descriptions>
          ),
          rowExpandable: record => record.name !== 'Not Expandable',
        }}
      />
    </>
  );
};
export default Settlements;
