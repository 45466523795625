import React, { useState, lazy, Suspense, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Table,
    Row,
    Col,
    Space,
    message,
    Popconfirm,
    Select,
} from 'antd';
import Highlighter from 'react-highlight-words';
import {
    getApiWithAuthToken,
    deleteApiWithAuthToken,
    updateApiWithAuthToken,
} from '../../../api';
import { SearchOutlined } from '@ant-design/icons';
const CompanyAdd = lazy(() => import('./CompanyAdd'));
const Company = () => {
    const [form] = Form.useForm();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [languageList, setLanguageList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [countryList, setCountryList] = useState([]);

    function getCurrencyList() {
        getApiWithAuthToken('/api/v1/admin/currencies').then((res) => {
            //console.log('data', res.data);
            if (res && res.data && res.data.length > 0) {
                setCurrencyList(res.data);
            }
        });
    }

    function getLanguageList() {
        getApiWithAuthToken('/api/v1/admin/languages').then((res) => {
            //console.log('data', res.data);
            if (res && res.data && res.data.length > 0) {
                setLanguageList(res.data);
            }
        });
    }
    function getCountryList() {
        getApiWithAuthToken('/api/v1/admin/countries').then((res) => {
            //console.log('data', res.data);
            if (res && res.data && res.data.length > 0) {
                setCountryList(res.data);
            }
        });
    }

    useEffect(() => {
        getLanguageList();
        getCurrencyList();
        getCountryList();
    }, []);

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let list = [];
        switch (dataIndex) {
            case 'lang':
                list = languageList;
                inputType = 'select';
                break;
            case 'currency':
                list = currencyList;
                inputType = 'select';
                break;
            case 'ho_country':
                list = countryList;
                inputType = 'select';
                break;
            default:
                list = [];
        }

        let selectbox = (
            <Select>
                {list && list.length
                    ? list.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))
                    : null}
            </Select>
        );
        const inputNode = inputType === 'select' ? selectbox : <Input />;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.id === editingKey;

    const handleEditClick = (record) => {
        //console.log(record);
        getApiWithAuthToken(`/api/v1/admin/companies/${record.id}`).then(
            (res) => {
                if (res.data) {
                    console.log(res.data);
                    form.setFieldsValue({
                        id: res.data[0].id,
                        name: res.data[0].name,
                        code: res.data[0].code,
                        lang: res.data[0].lang_id,
                        currency: res.data[0].currency_id,
                        ho_country: res.data[0].ho_country_id,
                        // ...record,
                    });
                    setEditingKey(record.id);
                }
            }
        );
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        const row = await form.validateFields();
        console.log(row);
        const id = key;
        updateApiWithAuthToken(`/api/v1/admin/companies/${id}`, row).then(
            (res) => {
                console.log(res);
                if (!res.error) {
                    setTimeout(() => {
                        message.success(res.msg);
                    }, 500);
                    setEditingKey('');
                    getCompanyList();
                } else {
                    setTimeout(() => {
                        message.error(res.errors);
                    }, 500);
                }
            }
        );
    };

    useEffect(() => {
        getCompanyList();
    }, []);
    // To Get all Table Data
    const getCompanyList = () => {
        getApiWithAuthToken('/api/v1/admin/companies').then((res) => {
            console.log(res);
            if (!res.error && res.data && res.data.length) {
                setCompanyList([]);
                res.data.map((each) =>
                    setCompanyList((companyList) => [
                        ...companyList,
                        {
                            id: each.id,
                            key: each.id,
                            name: each.name,
                            code: each.code,
                            lang: each.lang,
                            currency: each.currency,
                            ho_country: each.ho_country,
                        },
                    ])
                );
            } else {
                if (res.errors) {
                    Object.values(res.errors).map((each) =>
                        message.error(each.msg)
                    );
                    setCompanyList([]);
                } else {
                    if (res.errors) {
                        message.error(res.errors);
                        setCompanyList([]);
                    } else {
                        message.error({ content: 'Network Error!' });
                        setCompanyList([]);
                    }
                }
            }
        });
    };
    //Delete
    function handleDeleteClick(id) {
        deleteApiWithAuthToken(`/api/v1/admin/companies/${id}`).then((res) => {
            console.log(res);
            message.loading({ content: 'Loading...', duration: 0.5 });
            if (res.error) {
                setTimeout(() => {
                    if (res.errors.errno === 1451) {
                        message.error({
                            content:
                                'Could not delete!, The company is being referred in another place',
                            duration: 2,
                        });
                    } else {
                        message.error({
                            content: res.errors.sqlMessage,
                            duration: 2,
                        });
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    message.success({
                        content: 'Deleted!',
                        duration: 2,
                    });
                }, 1000);
                getCompanyList();
            }
        });
    }

    /* Table Functions started */
    const [searchText, setSearchText] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        // ref={node => {
                        //   this.searchInput = node;
                        // }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type='primary'
                            onClick={() =>
                                handleSearch(selectedKeys, confirm, dataIndex)
                            }
                            icon={<SearchOutlined />}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size='small'
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            onFilter: (value, record) =>
                record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    // setTimeout(() => this.searchInput.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: '#ffc069',
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                ) : (
                    text
                ),
        };
    }

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    function handleReset(clearFilters) {
        clearFilters();
        setSearchText('');
    }

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code'),
            sorter: (a, b) => a.code - b.code,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            className: 'table-col-capitalize',
            ...getColumnSearchProps('name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Language',
            dataIndex: 'lang',
            key: 'lang',
            className: 'table-col-capitalize',
            editable: true,
            ...getColumnSearchProps('lang'),
            sorter: (a, b) => a.lang.localeCompare(b.lang),
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            editable: true,
            className: 'table-col-capitalize',
            ...getColumnSearchProps('currency'),
            sorter: (a, b) => a.currency.localeCompare(b.currency),
        },
        {
            title: 'Country',
            dataIndex: 'ho_country',
            key: 'ho_country',
            className: 'table-col-capitalize',
            editable: true,
            ...getColumnSearchProps('ho_country'),
            sorter: (a, b) => a.ho_country.localeCompare(b.ho_country),
        },
        {
            title: 'Edit',
            key: 'edit',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            type='link'
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Button>
                        <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                            <Button type='link'>Cancel</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button
                        size='small'
                        disabled={editingKey !== ''}
                        onClick={() => handleEditClick(record)}
                    >
                        Edit
                    </Button>
                );
            },
        },
        {
            title: 'Delete',
            key: 'delete',
            render: (_, record) => (
                <Popconfirm
                    title='Are you sure to delete this country?'
                    onConfirm={() => handleDeleteClick(record.id)}
                >
                    <Button danger size='small'>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];
    /* Table Functions end */
    //Drawer Open function
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const showDrawerVisible = () => {
        showDrawer();
        form.resetFields();
    };
    //  Drawer Close function
    const handleCancel = () => {
        setIsDrawerVisible(false);
    };
    /*editable column seting function started*/
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    /*editable column seting function end*/
    return (
        <div>
            <Suspense fallback={<h1>Still Loading…</h1>}>
                <CompanyAdd
                    getCompanyList={getCompanyList}
                    setIsDrawerVisible={setIsDrawerVisible}
                    isDrawerVisible={isDrawerVisible}
                    handleCancel={handleCancel}
                />
            </Suspense>{' '}
            <Form form={form} component={false}>
                <Row style={{ margin: '2%' }}>
                    <Col span={24}>
                        <Table
                            title={() => (
                                <Row justify='space-between'>
                                    <h1 style={{ fontWeight: 'bold' }}>
                                        COMPANY
                                    </h1>
                                    <Button
                                        type='primary'
                                        onClick={showDrawerVisible}
                                    >
                                        ADD
                                    </Button>
                                </Row>
                            )}
                            bordered
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            columns={mergedColumns}
                            dataSource={companyList}
                            scroll={{ x: 1000 }}
                            size='small'
                            rowClassName='editable-row'
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
export default Company;
