import React, {useState, useEffect} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import {getApiWithAuthToken} from '../../../api';
import CustomerPriceForm from './CustomerPriceForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const CustomerPrice = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [customerPriceList, setCustomerPriceList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    ref_code: null,
    customer: null,
    price_key: null,
    field: null,
    order: null,
  });

  const getCustomerPriceList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/customerPrices?limit=${qp.limit}&page=${qp.page}` +
        (qp.customer ? `&customer=${qp.customer}` : '') +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.price_key ? `&price_key=${qp.price_key}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data?.customer_prices.length) {
        setTotalCount(res.data.total_count);
        setCustomerPriceList(
          res.data.customer_prices.map(each => ({
            id: each.id,
            code: each.code,
            ref_code: each.ref_code,
            price_key: each.price_key,
            from_date: each.from_date,
            to_date: each.to_date,
            customer: each.customer,
            last_sold_price: each.last_sold_price,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setCustomerPriceList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCustomerPriceList(queryParams);
  }, []);

  // Edit function

  const [selectedCustomerPrice, setSelectedCustomerPrice] = useState({});
  const handleEditClick = record => {
    setSelectedCustomerPrice(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedCustomerPrice({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getCustomerPriceList),
      sorter: true,
    },
    {
      title: 'Ref code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getCustomerPriceList),
      sorter: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      ...useColumnSearchProps('customer', queryParams, setQueryParams, getCustomerPriceList),
      sorter: false,
    },
    {
      title: 'Price Key',
      dataIndex: 'price_key',
      key: 'price_key',
      ...useColumnSearchProps('price_key', queryParams, setQueryParams, getCustomerPriceList),
      sorter: false,
    },
    {
      title: 'From Date',
      dataIndex: 'from_date',
      key: 'from_date',
      sorter: true,
    },
    {
      title: 'To Date',
      dataIndex: 'to_date',
      key: 'to_date',
      sorter: true,
    },
    {
      title: 'Last sold price',
      dataIndex: 'last_sold_price',
      key: 'last_sold_price',
      sorter: false,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this customer price?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/customerPrices/', () =>
                getCustomerPriceList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Customer Price
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={customerPriceList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getCustomerPriceList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} -${range[1]} of ${total} items`,
        }}
      />
      <CustomerPriceForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getCustomerPriceList={getCustomerPriceList}
        customerPrice={selectedCustomerPrice}
      />
    </>
  );
};

export default CustomerPrice;
