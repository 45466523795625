import React, {useState, useEffect, Fragment} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import {connect} from 'react-redux';
import {
  ShoppingOutlined,
  UserOutlined,
  EnvironmentOutlined,
  TransactionOutlined,
  CarOutlined,
  HomeOutlined,
  TagOutlined,
} from '@ant-design/icons';
const {Sider} = Layout;
const {SubMenu} = Menu;

const items = [
  [
    {key: '1', label: 'Area', path: '/area'},
    {key: '2', label: 'Region', path: '/region'},
    {
      key: '3',
      label: 'Currency Conversion',
      path: '/currency-conversion',
    },
    {key: '4', label: 'Country Managers', path: '/country-managers'},
  ],
  [
    // { key: '3', label: 'User', path: '/user' },
    // { key: '4', label: 'Collector', path: '/collector' },
    {key: '5', label: 'Customer Type', path: '/customer-types'},
    {key: '6', label: 'Customer', path: '/customer'},
    {key: '7', label: 'Manager ', path: '/manager'},
    {key: '8', label: 'Salesman', path: '/salesman'},
    {key: '9', label: 'Salesman Log', path: '/salesman/log'},
  ],
  [
    {key: '10', label: 'Product', path: '/product'},
    {key: '11', label: 'Product Category', path: '/product-category'},
    {
      key: '12',
      label: 'Product Subcategory',
      path: '/product-subcategory',
    },
    {key: '13', label: 'Product Multipack ', path: '/product-multipack'},
  ],
  [
    {key: '14', label: 'Price Key', path: '/price-key'},
    {key: '15', label: 'Customer Price', path: '/customer-price'},
    {key: '16', label: 'Route Price ', path: '/route-price'},
    {key: '17a', label: 'Product Price', path: '/product-price'},
    // { key: '16', label: 'Salesman Price', path: '/salesman-price' },
    // { key: '18', label: 'Payment Type ', path: '/payment-type' },
  ],
  [
    {key: '18', label: 'Route', path: '/route'},
    {key: '19', label: 'Route Journey Plan', path: '/route-journey-plan'},
    {key: '20', label: 'Route Last Code', path: '/route-last-code'},
    {key: '21', label: 'Custom Document Code', path: '/custom-document-code'},
    {key: '22', label: 'Branch', path: '/branch'},
    {key: '23', label: 'Warehouse', path: '/warehouse'},
  ],
  [
    {key: '24', label: 'Sales', path: '/sales-summary'},
    {key: '25', label: 'Collections', path: '/collections-summary'},
    // {key: '24', label: 'Load', path: '/load-summary'},
    // {key: '25', label: 'Unload', path: '/unload-summary'},
    {key: '26', label: 'Advance Collections', path: '/advance-collections'},
    {key: '27', label: 'Settlements', path: '/settlements'},
  ],
];

const SiderView = () => {
  const history = useHistory();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState([]);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };
  const onClickMenu = item => {
    items.map(each =>
      each.map(_item => (_item.key === item.key ? history.push(_item.path) : null))
    );
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setSelectedKey(['100']);
      // } else if (location.pathname === '/company-settings') {
      //     setSelectedKey(['22']);
    } else {
      items.map(item =>
        item.find(_item => location.pathname === _item.path)
          ? setSelectedKey([item.find(_item => location.pathname === _item.path).key])
          : []
      );
    }
  }, [location]);

  return (
    <Fragment>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} theme="light">
        <Menu selectedKeys={selectedKey} mode="inline" onClick={onClickMenu}>
          <Menu.Item key="100" icon={<HomeOutlined />} onClick={() => history.push('/')}>
            <span>Dashboard</span>
          </Menu.Item>
          {/* <Menu.Item key='22' icon={<HomeOutlined />} onClick={() => history.push('/company-settings')}>
                        <span>Company Settings</span>
                    </Menu.Item> */}
          <SubMenu key="sub1" icon={<EnvironmentOutlined />} title="Locale Masters">
            {items[0].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="sub2" icon={<UserOutlined />} title="User Masters">
            {items[1].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="sub3" icon={<ShoppingOutlined />} title="Product Masters">
            {items[2].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="sub4" icon={<TagOutlined />} title="Price Masters">
            {items[3].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="sub5" icon={<CarOutlined />} title="Route Masters">
            {items[4].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
          <SubMenu key="sub6" icon={<TransactionOutlined />} title="Transactions">
            {items[5].map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Sider>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SiderView);
