import React from 'react';
import RegionTable from '../../../components/LocaleMasters/Region/RegionTable';
import ContentView from '../../../components/Layouts/ContentView';
const index = () => {
    return (
        <ContentView>
            <RegionTable />
        </ContentView>
    );
};
export default index;
