import React, {useState, useEffect, useRef} from 'react';
import {Input, Button, Table, Row, Space, message, Popconfirm, Typography} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {getApiWithAuthToken, deleteApiWithAuthToken} from '../../../api';
import ProductMultipackForm from './ProductMultipackForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const ProductMultipack = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [productMultipackList, setProductMultipackList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    name: null,
    code: null,
    ref_code: null,
    field: null,
    order: null,
  });

  const getProductMultipackList = qp => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/productMultipacks?limit=${qp.limit}&page=${qp.page}` +
        (qp.name ? `&name=${qp.name}` : '') +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data.productMultipacks.length) {
        setTotalCount(res.data.total_count);
        setProductMultipackList(
          res.data.productMultipacks.map(each => ({
            id: each.id,
            code: each.code,
            name: each.name,
            ref_code: each.ref_code,
            base_unit: each.base_unit,
            base_multipack: each.base_multipack,
            multiplier: each.multiplier,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
          setProductMultipackList([]);
        } else {
          message.error({content: 'Network Error!'});
        }
        setProductMultipackList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductMultipackList(queryParams);
  }, []);

  // Edit function
  const [selectedProductMultipack, setSelectedProductMultipack] = useState({});
  const handleEditClick = record => {
    setSelectedProductMultipack(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedProductMultipack({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getProductMultipackList),
      sorter: true,
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getProductMultipackList),
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...useColumnSearchProps('name', queryParams, setQueryParams, getProductMultipackList),
      sorter: true,
    },
    {
      title: 'Base Unit',
      dataIndex: 'base_unit',
      key: 'base_unit',
      sorter: true,
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>{' '}
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this product multipack?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/productMultipacks/', () =>
                getProductMultipackList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Product Multipack
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={productMultipackList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getProductMultipackList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
        }}
      />
      <ProductMultipackForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getProductMultipackList={getProductMultipackList}
        productMultipack={selectedProductMultipack}
      />
    </>
  );
};

export default ProductMultipack;
