import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Table,
    Row,
    Col,
    message,
    Popconfirm,
} from 'antd';
import { useColumnSearchProps } from '../../../helpers/table';
import { getApiWithAuthToken, updateApiWithAuthToken } from '../../../api';
import EditableCell from './CountryEdit';

const Country = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        getCountryList(pageSize, currentPage);
    }, []);

    const isEditing = (record) => record.key === editingKey;

    const handleEditClick = (record) => {
        console.log(record);
        form.setFieldsValue({
            name: '',
            prefix: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        const row = await form.validateFields();
        console.log(row);
        const id = key;
        setLoading(true);
        updateApiWithAuthToken(`/api/v1/master/countries/${id}`, row).then(
            (res) => {
                if (!res.error) {
                    setTimeout(() => {
                        message.success(res.msg);
                    }, 500);
                    setEditingKey('');
                    getCountryList(pageSize, currentPage);
                    setLoading(false);
                } else {
                    setTimeout(() => {
                        message.error(res.errors);
                    }, 500);
                    setLoading(false);
                }
            }
        );
    };

    // Editing section end

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize);
        setCurrentPage(pagination.current);
        getCountryList(
            pagination.pageSize,
            pagination.current,
            filters.name,
            filters.prefix,
            sorter.field,
            sorter.order
        );
    };

    // To Get all Table Data
    const getCountryList = (
        limit = 10,
        page = 1,
        name = null,
        prefix = null,
        field = null,
        order = null
    ) => {
        setLoading(true);
        getApiWithAuthToken(
            `/api/v1/master/countries?limit=${limit}&page=${page}${(name == null ? '' : '&name=' + name) +
            (prefix == null ? '' : '&prefix=' + prefix) +
            (field == null ? '' : '&field=' + field) +
            (order == null ? '' : '&order=' + order)
            }`
        ).then((res) => {
            if (!res.error && res.data && res.data.countries.length) {
                setTotalCount(res.data.total_count);
                setCountryList([]);
                res.data.countries.map((each) =>
                    setCountryList((countryList) => [
                        ...countryList,
                        {
                            id: each.id,
                            key: each.id,
                            name: each.name,
                            prefix: each.prefix,
                        },
                    ])
                );
                setLoading(false);
            } else {
                if (res.errors[0].msg) {
                    console.log(res);
                    res.errors.map((each) => message.error(each.msg));
                    setCountryList([]);
                } else {
                    if (res.errors) {
                        message.error(res.errors);
                        setCountryList([]);
                    } else {
                        message.error({ content: 'Network Error!' });
                        setCountryList([]);
                    }
                }
                setLoading(false);
            }
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            className: 'table-col-capitalize',
            ...useColumnSearchProps('name'),
            sorter: true,
        },

        {
            title: 'Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
            className: 'table-col-capitalize',
            editable: true,
            ...useColumnSearchProps('prefix'),
            sorter: true,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            href='#'
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </a>
                        <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button
                        size='small'
                        disabled={editingKey !== ''}
                        onClick={() => handleEditClick(record)}
                    >
                        Edit
                    </Button>
                );
            },
        },
    ];
    /* Table Functions end */

    /* Editable column settings */
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    /* Editable column settings end */

    return (
        <div>
            <Form form={form} component={false}>
                <Row style={{ margin: '2%' }}>
                    <Col span={24}>
                        <Table
                            title={() => (
                                <Row justify='space-between'>
                                    <h1 style={{ fontWeight: 'bold' }}>COUNTRY LIST</h1>
                                </Row>
                            )}
                            bordered
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            loading={loading}
                            columns={mergedColumns}
                            dataSource={countryList}
                            scroll={{ x: 500 }}
                            size='small'
                            tableLayout='fixed'
                            rowClassName='editable-row'
                            onChange={handleTableChange}
                            pagination={{
                                showSizeChanger: true,
                                total: totalCount,
                                current: currentPage,
                                size: 'default',
                                pageSize: pageSize,
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Country;
