import React, {useState, useEffect} from 'react';
import {Button, Table, Row, message, Popconfirm, Typography} from 'antd';
import {getApiWithAuthToken} from '../../../api';
import ProductForm from './ProductForm';
import {useColumnSearchProps, handleDeleteClick, handleTableChange} from '../../../helpers/table';

const Product = () => {
  // Table states
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    limit: 10,
    page: 1,
    code: null,
    ref_code: null,
    name: null,
    category: null,
    subcategory: null,
    field: null,
    order: null,
  });

  const getProductList = (qp = queryParams) => {
    setLoading(true);
    getApiWithAuthToken(
      `/api/v1/admin/products?limit=${qp.limit}&page=${qp.page}` +
        (qp.code ? `&code=${qp.code}` : '') +
        (qp.ref_code ? `&ref_code=${qp.ref_code}` : '') +
        (qp.name ? `&name=${qp.name}` : '') +
        (qp.category ? `&category=${qp.category}` : '') +
        (qp.subcategory ? `&subcategory=${qp.subcategory}` : '') +
        (qp.field ? `&field=${qp.field}` : '') +
        (qp.order ? `&order=${qp.order}` : '')
    ).then(res => {
      if (!res.error && res.data.products.length) {
        setTotalCount(res.data.total_count);
        setProductList(
          res.data.products.map(each => ({
            id: each.id,
            code: each.code,
            name: each.name,
            ref_code: each.ref_code,
            category: each.category,
            subcategory: each.subcategory,
            barcodes: each.barcodes,
          }))
        );
      } else {
        if (res.errors) {
          Object.values(res.errors).map(each => message.error(each.msg));
        } else {
          message.error({content: 'Network Error!'});
        }
        setProductList([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductList(queryParams);
  }, []);

  // Edit function
  const [selectedProduct, setSelectedProduct] = useState({});
  const handleEditClick = record => {
    setSelectedProduct(record);
    setIsDrawerVisible(true);
  };

  //Drawer Open function
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => {
    setSelectedProduct({});
    setIsDrawerVisible(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ...useColumnSearchProps('code', queryParams, setQueryParams, getProductList),
      sorter: true,
    },
    {
      title: 'Ref Code',
      dataIndex: 'ref_code',
      key: 'ref_code',
      ...useColumnSearchProps('ref_code', queryParams, setQueryParams, getProductList),
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...useColumnSearchProps('name', queryParams, setQueryParams, getProductList),
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      ...useColumnSearchProps('category', queryParams, setQueryParams, getProductList),
      sorter: true,
    },
    {
      title: 'Sub-Category',
      dataIndex: 'subcategory',
      key: 'subcategory',
      ...useColumnSearchProps('subcategory', queryParams, setQueryParams, getProductList),
      sorter: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <>
          <Button size="small" onClick={() => handleEditClick(record)}>
            Edit
          </Button>{' '}
          <span> </span>
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={() =>
              handleDeleteClick(record.id, '/api/v1/admin/products/', () =>
                getProductList(queryParams)
              )
            }
          >
            <Button danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{margin: '24px 0'}}>
        <Typography.Title level={3} style={{marginBottom: '0'}}>
          Product{' '}
        </Typography.Title>
        <Button type="primary" onClick={showDrawer}>
          Add
        </Button>
      </Row>
      <Table
        scroll={{x: 0}}
        dataSource={productList}
        columns={columns}
        loading={loading}
        onChange={handleTableChange(queryParams, setQueryParams, getProductList)}
        pagination={{
          showSizeChanger: true,
          total: totalCount,
          current: queryParams.page,
          pageSize: queryParams.limit,
          size: 'default',
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
        }}
      />
      <ProductForm
        handleDrawerClose={handleDrawerClose}
        isDrawerVisible={isDrawerVisible}
        getProductList={getProductList}
        product={selectedProduct}
      />
    </>
  );
};

export default Product;
